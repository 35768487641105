interface MediaItem {
  id: string;
  type: "playlist" | "video";
  title: string;
  description: string;
  url: string;
}

export const mediaItems: MediaItem[] = [
  {
    id: "1",
    type: "playlist",
    title: "My Favorite Pop Rock Christian Playlist",
    description:
      "A playlist of uplifting and inspiring Christian Pop Rock songs.",
    url: "https://www.youtube.com/embed/videoseries?list=PLvB1e08cXGIlnLk1sSH3DbFqKZPuxxIZO", // Embed URL for a regular YouTube playlist
  },
  {
    id: "2",
    type: "playlist",
    title: "Spanish Pop Rock Christian Playlist",
    description:
      "A playlist of uplifting and inspiring Christian Pop Rock songs in Spanish.",
    url: "https://music.youtube.com/playlist?list=PLvB1e08cXGIlKFMRhN4AuzP1Fqc8KACpm&si=8SSJtFvfy_-Iqo0k", // Regular YouTube video URL
  },
  {
    id: "3",
    type: "playlist",
    title: "Hard Rock Playlist to Praise God with Energy!",
    description:
      "A playlist of Christian Hard/Metal Rock songs to praise God with energy.",
    url: "https://music.youtube.com/playlist?list=PLvB1e08cXGIne6Pca1HjTemoa-Kj8ixOi&si=Eu2GPp1ZykFoDEDl", // Regular YouTube video URL
  },
  {
    id: "4",
    type: "video",
    title: "Scientific Facts about the Bible",
    description:
      "If it's true that the Bible contains scientific facts that were written thousands of years before man discovered them, the implications are staggering. These facts would be evidence that the Bible is the word of God, and its promise of Heaven and threat of Hell are therefore not to be mocked or ignored. ",
    url: "https://www.youtube.com/watch?v=t2sMJMXDiH4", // Regular YouTube video URL
  },
  {
    id: "5",
    type: "video",
    title: "Alice Cooper: A Testimony of Finding Purpose Through God's Grace | Praise on TBN",
    description:
      "Alice Cooper sits down with Greg Laurie on TBN's Praise to share his powerful testimony about searching for purpose at the height of fame and finding God's grace at his lowest point.",
    url: "https://youtu.be/OqkzpZYLwTs?si=n_OqYJYQ8EVJAKum", // Regular YouTube video URL
  },
  {
    id: "6",
    type: "video",
    title: "Making Sense of Me: My Emotions | Jon Jorgenson | Soul City Church",
    description:
      "Our emotions are one of the most complex parts of us. We can feel one thing one minute, and then something completely different the next. And sometimes, we can feel multiple things at once.",
    url: "https://youtu.be/Z9Nfkx80faE?si=SOoRAtqUSWYwH-pO", // Regular YouTube video URL
  },
  {
    id: "7",
    type: "video",
    title: "Voddie Baucham: Do Not Love the World",
    description:"First John 2:15–17 commands us not to love the world or the things of the world. Setting our hearts on the world is incompatible with love for God.",
    url: "https://youtu.be/9jYtODX22ZY?si=UiuXcewaG-hsn54J", // Regular YouTube video URL
  },
  {
    id: "8",
    type: "video",
    title: "Catholicism VS Christianity",
    description:"Is there a difference between Catholics and Christians? Is the Word of God the only book that is infallible? In this video, I discuss the many differences between Catholicism and Christianity and why there is only one way you can be saved: by grace through faith in our Lord Jesus Christ.",
      url: "https://youtu.be/5JmFTAFXt9w?si=dVafkCS_qkr_mR_1", // Regular YouTube video URL

  },
  {
    id: "9",
    type: "video",
    title: "The Chosen - Jesus and the Outcast Woman at the Well",
    description:"The Chosen is the first-ever multi-season series based on the true stories of the gospels of Jesus Christ. This episode is about Jesus and the outcast woman at the well.",
    url: "https://youtu.be/ordhsDeAt60?si=VMN3RCi9JYtbrbsW", // Regular YouTube video URL
  },
  {
    id: "10",
    type: "video",
    title: "Nicholas Bowling - Understanding the Works of the Flesh Pt. 3 | LIVE Q&A",
    description:"Nicholas Bowling is a Christian YouTuber who shares his knowledge of the Bible and the teachings of Jesus Christ. In this video, he discusses the works of the flesh and answers questions from his viewers.",
    url: "https://www.youtube.com/live/UKMKV9JdXGw?si=Ucq9Q2MrtdS4YAML", // Regular YouTube
  }, 
  {
    id: "11",
    type: "video",
    title: "The Four Loves... Romantic Love | Jon Jorgenson | Soul City Church",
    description:"Sexual love is one of the most pervasive and powerful forces in the world. In our world, sex is easy to come by, but hard to talk about. Traditionally, the church’s response is often to over-restrict sex.",
    url: "https://youtu.be/mkgCrhi8V-4?si=JWM9PWnAfkNaLvXM", // Regular YouTube video URL
  },
  {
    id: "12",
    type: "video",
    title: "Living Waters - Ray Comfort Gives the Audience an URGENT Warning",
    description:"Ray Comfort preached a sermon at Jack Hibbs’ church, Calvary Chapel Chino Hills, where he talks about an essential aspect of the Christian faith: the fear of God.",
    url: "https://youtu.be/buqHhudG4Z4?si=rZqqJCMxOSK7dGh3", // Regular YouTube video URL
  },
  {
    id: "13",
    type: "video",
    title: "Allen Parr - The Catholic Church is Promoting These 7 DANGEROUS False Teachings!",
    description:"In this video I want to talk about 7 theologies being taught and promoted by the Catholic Church that are absolutely false.",
    url: "https://youtu.be/DqJyqJNTwo4?si=Q8oEyc2g0tcqUXk7", // Regular YouTube video URL
  }
];
