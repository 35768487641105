import React, { useState } from "react";
import FlipbookViewer from "./FlipBookViewer";
import { BibleStudyPdfs } from "../constants/BibleStudyPdfs";

const BibleStudyFlipBooks: React.FC = () => {
  const [selectedPdf, setSelectedPdf] = useState<{
    pdf: string;
    author: string;
  } | null>(null);

  return (
    <div className="p-6 bg-black bg-opacity-60 shadow-lg mx-auto mx-auto border-white-500 border-y">
      <h2 className="text-2xl md:text-3xl text-white font-bold mb-4 text-center uppercase mt-5">
        Bible Study Books & Resources
      </h2>
      <p className="text-white text-lg text-center p-1 mb-4">
        Here's some really nice Bible study books I found online (with the
        respected authors shown) I recommend which I think will help in your
        spiritual growth with studying the Bible.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-10">
        {BibleStudyPdfs.map((pdf, index) => (
          <div
            key={index}
            className="cursor-pointer"
            onClick={() => setSelectedPdf({ pdf: pdf.pdf, author: pdf.author })}
          >
            <img
              src={pdf.thumbnail}
              alt={pdf.title}
              className="w-full h-auto max-h-64 object-contain rounded-lg shadow-md"
              width={600}
              height={300}
            />
            <h3 className="text-center text-white mt-2">{pdf.title}</h3>
            <p className="text-center text-gray-300 text-sm">{pdf.author}</p>
          </div>
        ))}
      </div>

      {selectedPdf && (
        <FlipbookViewer
          pdfUrl={selectedPdf.pdf}
          author={selectedPdf.author}
          onClose={() => setSelectedPdf(null)}
        />
      )}
    </div>
  );
};

export default BibleStudyFlipBooks;
