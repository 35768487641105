import React from "react";
import newLogo from "../../assets/newlogo.png";

const AboutMeIntro: React.FC = () => {
  return (
    <div className="relative bg-black bg-opacity-30 rounded-lg p-6 shadow-lg max-w-sm">
      {/* Profile Section */}
      <div className="flex items-center space-x-4 mb-4">
        {/* Profile Image */}
        <img
          src={newLogo}
          alt="Live For Jesus Christ"
          className="w-16 h-16 rounded-full border-2 border-white"
        />
        {/* Name and Follower Info */}
        <div>
          <h3 className="text-sm font-bold text-blue-400 uppercase">
            Alan Jones
          </h3>

          <div className="mt-2">
            <h3 className="text-sm font-bold text-pink-400 uppercase">
              Maria Luisa Jones
            </h3>
          </div>
        </div>
      </div>

      {/* Short Bio */}
      <p className="text-gray-300 text-sm mb-4">
        Followers of Jesus Christ, devoted daughter and son of our Lord, with a
        passion for software and technology, engineering, sociology, and
        justice.
      </p>

      {/* Personal Website */}
      <a
        href="http://liveforjesuschrist.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-400 hover:text-blue-500 underline text-sm block mb-6"
      >
        http://liveforjesuschrist.com/
      </a>

      {/* Combined Section: People I Follow and References */}
      <div className="mb-4">
        <p className="text-left text-gray-300 text-sm space-y-2">
          Here's a list of some of the great resources that we have found very
          helpful from people, churches, and ministries that I follow. I hope
          you find them helpful too!
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        {/* People I Follow Section */}
        <div className="flex-1">
          <h4 className="text-lg font-bold text-yellow-400 mb-2">
            People I Follow:
          </h4>

          <ul className="space-y-2">
            <li>
              <a
                href="https://www.youtube.com/@graciacc"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Gracia Calvary Chapel
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@raycomfortjustwitnessing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Ray Comfort
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/theBibleProject"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                The Bible Project
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@JonJorgensonVideos"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                John Jorgenson
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/watch?v=9jYtODX22ZY&t=5s"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Voddie Baucham
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@calvaryftl"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Calvary Chapel Fort Lauderdale
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/c/LivingWaters"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Living Waters
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@nicholasbowlingministries"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Nicholas Bowling Ministries
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@thebeatagp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                THE BEAT by Allen Parr
              </a>
            </li>
            <li></li>
            <li>
              <a
                href="https://www.youtube.com/@craiggroeschel"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Craig Groeschel
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@KoinoniaLima"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Koinonia Lima
              </a>
            </li>
          </ul>
        </div>

        {/* References Section */}
        <div className="flex-1">
          <h4 className="text-lg font-bold text-yellow-400 mb-2">
            References:
          </h4>
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.youversion.com/the-bible-app/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                YouVersion Bible App
              </a>
            </li>
            <li>
              <a
                href="https://biblehub.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Bible Hub
              </a>
            </li>
            <li>
              <a
                href="https://biblestudytools.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Bible Study Tools
              </a>
            </li>
            <li>
              <a
                href="https://livingwaters.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Living Waters Site
              </a>
            </li>
            <li>
              <a
                href="https://watch.thechosen.tv/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                The Chosen
              </a>
            </li>
            <li>
              <a
                href="https://www.tyndale.com/sites/lasb/?srsltid=AfmBOopCe9OY-LiBxGYmSnnfXTSe8_EO4ICfWPCLzv6NH2n7RwZTIww0"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Life Application Study Bible
              </a>
            </li>
            <li>
              <a
                href="https://paper.bible/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Paper Bible - Free app for printing Scripture
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutMeIntro;
