export const DepressionVerses = [
  {
    chapter: 42,
    verse: 11,
    reference: "Psalm 42:11",
    text: "Why, my soul, are you downcast? Why so disturbed within me? Put your hope in God, for I will yet praise him, my Savior and my God.",
  },
  {
    chapter: 11,
    verse: 28,
    reference: "Matthew 11:28",
    text: "Come to me, all you who are weary and burdened, and I will give you rest.",
  },
  {
    chapter: 34,
    verse: 18,
    reference: "Psalm 34:18",
    text: "The Lord is close to the brokenhearted and saves those who are crushed in spirit.",
  },
  {
    chapter: 3,
    verse: 22,
    reference: "Lamentations 3:22-23",
    text: "Because of the Lord’s great love we are not consumed, for his compassions never fail. They are new every morning; great is your faithfulness.",
  },
  {
    chapter: 30,
    verse: 5,
    reference: "Psalm 30:5",
    text: "For his anger lasts only a moment, but his favor lasts a lifetime; weeping may stay for the night, but rejoicing comes in the morning.",
  },
  {
    chapter: 9,
    verse: 10,
    reference: "Isaiah 41:10",
    text: "So do not fear, for I am with you; do not be dismayed, for I am your God. I will strengthen you and help you; I will uphold you with my righteous right hand.",
  },
  {
    chapter: 4,
    verse: 8,
    reference: "Philippians 4:8",
    text: "Finally, brothers and sisters, whatever is true, whatever is noble, whatever is right, whatever is pure, whatever is lovely, whatever is admirable—if anything is excellent or praiseworthy—think about such things.",
  },
  {
    chapter: 23,
    verse: 4,
    reference: "Psalm 23:4",
    text: "Even though I walk through the darkest valley, I will fear no evil, for you are with me; your rod and your staff, they comfort me.",
  },
  {
    chapter: 4,
    verse: 7,
    reference: "Philippians 4:7",
    text: "And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
  },
  {
    chapter: 8,
    verse: 28,
    reference: "Romans 8:28",
    text: "And we know that in all things God works for the good of those who love him, who have been called according to his purpose.",
  },
  {
    chapter: 31,
    verse: 8,
    reference: "Deuteronomy 31:8",
    text: "The Lord himself goes before you and will be with you; he will never leave you nor forsake you. Do not be afraid; do not be discouraged.",
  },
  {
    chapter: 43,
    verse: 2,
    reference: "Isaiah 43:2",
    text: "When you pass through the waters, I will be with you; and when you pass through the rivers, they will not sweep over you. When you walk through the fire, you will not be burned; the flames will not set you ablaze.",
  },
  {
    chapter: 94,
    verse: 19,
    reference: "Psalm 94:19",
    text: "When anxiety was great within me, your consolation brought me joy.",
  },
  {
    chapter: 12,
    verse: 9,
    reference: "2 Corinthians 12:9",
    text: "But he said to me, 'My grace is sufficient for you, for my power is made perfect in weakness.' Therefore I will boast all the more gladly about my weaknesses, so that Christ’s power may rest on me.",
  },
  {
    chapter: 14,
    verse: 1,
    reference: "John 14:1",
    text: "Do not let your hearts be troubled. You believe in God; believe also in me.",
  },
  {
    chapter: 46,
    verse: 1,
    reference: "Psalm 46:1",
    text: "God is our refuge and strength, an ever-present help in trouble.",
  },
  {
    chapter: 61,
    verse: 3,
    reference: "Isaiah 61:3",
    text: "To provide for those who grieve in Zion—to bestow on them a crown of beauty instead of ashes, the oil of joy instead of mourning, and a garment of praise instead of a spirit of despair.",
  },
  {
    chapter: 21,
    verse: 4,
    reference: "Revelation 21:4",
    text: "He will wipe every tear from their eyes. There will be no more death or mourning or crying or pain, for the old order of things has passed away.",
  },
  {
    chapter: 7,
    verse: 14,
    reference: "2 Chronicles 7:14",
    text: "If my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then I will hear from heaven, and I will forgive their sin and will heal their land.",
  },
  {
    chapter: 7,
    verse: 38,
    reference: "John 7:38",
    text: "Whoever believes in me, as Scripture has said, rivers of living water will flow from within them.",
  },
  {
    chapter: 5,
    verse: 3,
    reference: "Matthew 5:3-4",
    text: "Blessed are the poor in spirit, for theirs is the kingdom of heaven. Blessed are those who mourn, for they will be comforted.",
  },
  {
    chapter: 28,
    verse: 20,
    reference: "Matthew 28:20",
    text: "And surely I am with you always, to the very end of the age.",
  },
  {
    chapter: 27,
    verse: 1,
    reference: "Psalm 27:1",
    text: "The Lord is my light and my salvation—whom shall I fear? The Lord is the stronghold of my life—of whom shall I be afraid?",
  },
];
