export const GreedVerses = [
  {
    chapter: 6,
    verse: 10,
    reference: "1 Timothy 6:10",
    text: "For the love of money is a root of all kinds of evil. Some people, eager for money, have wandered from the faith and pierced themselves with many griefs.",
  },
  {
    chapter: 12,
    verse: 15,
    reference: "Luke 12:15",
    text: "Then he said to them, 'Watch out! Be on your guard against all kinds of greed; life does not consist in an abundance of possessions.'",
  },
  {
    chapter: 3,
    verse: 5,
    reference: "Colossians 3:5",
    text: "Put to death, therefore, whatever belongs to your earthly nature: sexual immorality, impurity, lust, evil desires and greed, which is idolatry.",
  },
  {
    chapter: 13,
    verse: 5,
    reference: "Hebrews 13:5",
    text: "Keep your lives free from the love of money and be content with what you have, because God has said, 'Never will I leave you; never will I forsake you.'",
  },
  {
    chapter: 1,
    verse: 19,
    reference: "Proverbs 15:27",
    text: "The greedy bring ruin to their households, but the one who hates bribes will live.",
  },
  {
    chapter: 21,
    verse: 26,
    reference: "Proverbs 21:26",
    text: "All day long he craves for more, but the righteous give without sparing.",
  },
  {
    chapter: 23,
    verse: 4,
    reference: "Proverbs 23:4-5",
    text: "Do not wear yourself out to get rich; do not trust your own cleverness. Cast but a glance at riches, and they are gone, for they will surely sprout wings and fly off to the sky like an eagle.",
  },
  {
    chapter: 25,
    verse: 8,
    reference: "Ecclesiastes 5:10",
    text: "Whoever loves money never has enough; whoever loves wealth is never satisfied with their income. This too is meaningless.",
  },
  {
    chapter: 6,
    verse: 24,
    reference: "Matthew 6:24",
    text: "No one can serve two masters. Either you will hate the one and love the other, or you will be devoted to the one and despise the other. You cannot serve both God and money.",
  },
  {
    chapter: 8,
    verse: 36,
    reference: "Mark 8:36",
    text: "What good is it for someone to gain the whole world, yet forfeit their soul?",
  },
  {
    chapter: 11,
    verse: 28,
    reference: "Proverbs 11:28",
    text: "Those who trust in their riches will fall, but the righteous will thrive like a green leaf.",
  },
  {
    chapter: 10,
    verse: 19,
    reference: "Micah 2:1-2",
    text: "Woe to those who plan iniquity, to those who plot evil on their beds! At morning’s light they carry it out because it is in their power to do it. They covet fields and seize them, and houses, and take them. They defraud people of their homes, they rob them of their inheritance.",
  },
  {
    chapter: 6,
    verse: 25,
    reference: "Ecclesiastes 6:7",
    text: "Everyone’s toil is for their mouth, yet their appetite is never satisfied.",
  },
  {
    chapter: 22,
    verse: 9,
    reference: "Proverbs 22:9",
    text: "The generous will themselves be blessed, for they share their food with the poor.",
  },
  {
    chapter: 4,
    verse: 8,
    reference: "Ephesians 5:5",
    text: "For of this you can be sure: No immoral, impure or greedy person—such a person is an idolater—has any inheritance in the kingdom of Christ and of God.",
  },
  {
    chapter: 20,
    verse: 21,
    reference: "Proverbs 28:22",
    text: "The stingy are eager to get rich and are unaware that poverty awaits them.",
  },
  {
    chapter: 5,
    verse: 16,
    reference: "1 Timothy 6:6-7",
    text: "But godliness with contentment is great gain. For we brought nothing into the world, and we can take nothing out of it.",
  },
  {
    chapter: 8,
    verse: 20,
    reference: "Isaiah 5:8",
    text: "Woe to you who add house to house and join field to field till no space is left and you live alone in the land.",
  },
  {
    chapter: 12,
    verse: 17,
    reference: "Luke 12:17-19",
    text: "He thought to himself, ‘What shall I do? I have no place to store my crops.’ Then he said, ‘This is what I’ll do. I will tear down my barns and build bigger ones, and there I will store my surplus grain. And I’ll say to myself, “You have plenty of grain laid up for many years. Take life easy; eat, drink and be merry.”'",
  },
  {
    chapter: 5,
    verse: 2,
    reference: "Amos 5:11",
    text: "You levy a straw tax on the poor and impose a tax on their grain. Therefore, though you have built stone mansions, you will not live in them; though you have planted lush vineyards, you will not drink their wine.",
  },
  {
    chapter: 20,
    verse: 35,
    reference: "Acts 20:35",
    text: "In everything I did, I showed you that by this kind of hard work we must help the weak, remembering the words the Lord Jesus himself said: 'It is more blessed to give than to receive.'",
  },
  {
    chapter: 5,
    verse: 3,
    reference: "James 5:3",
    text: "Your gold and silver are corroded. Their corrosion will testify against you and eat your flesh like fire. You have hoarded wealth in the last days.",
  },
  {
    chapter: 16,
    verse: 19,
    reference: "Proverbs 16:19",
    text: "Better to be lowly in spirit along with the oppressed than to share plunder with the proud.",
  },
  {
    chapter: 19,
    verse: 17,
    reference: "Proverbs 19:17",
    text: "Whoever is kind to the poor lends to the Lord, and he will reward them for what they have done.",
  },
];
