export const FriendshipVerses = [
  {
    chapter: 17,
    verse: 17,
    reference: "Proverbs 17:17",
    text: "A friend loves at all times, and a brother is born for a time of adversity.",
  },
  {
    chapter: 27,
    verse: 17,
    reference: "Proverbs 27:17",
    text: "As iron sharpens iron, so one person sharpens another.",
  },
  {
    chapter: 18,
    verse: 24,
    reference: "Proverbs 18:24",
    text: "One who has unreliable friends soon comes to ruin, but there is a friend who sticks closer than a brother.",
  },
  {
    chapter: 13,
    verse: 20,
    reference: "Proverbs 13:20",
    text: "Walk with the wise and become wise, for a companion of fools suffers harm.",
  },
  {
    chapter: 4,
    verse: 9,
    reference: "Ecclesiastes 4:9-10",
    text: "Two are better than one, because they have a good return for their labor: If either of them falls down, one can help the other up. But pity anyone who falls and has no one to help them up.",
  },
  {
    chapter: 15,
    verse: 33,
    reference: "John 15:13",
    text: "Greater love has no one than this: to lay down one’s life for one’s friends.",
  },
  {
    chapter: 22,
    verse: 24,
    reference: "Proverbs 22:24-25",
    text: "Do not make friends with a hot-tempered person, do not associate with one easily angered, or you may learn their ways and get yourself ensnared.",
  },
  {
    chapter: 15,
    verse: 13,
    reference: "John 15:12",
    text: "My command is this: Love each other as I have loved you.",
  },
  {
    chapter: 6,
    verse: 10,
    reference: "Galatians 6:2",
    text: "Carry each other’s burdens, and in this way you will fulfill the law of Christ.",
  },
  {
    chapter: 5,
    verse: 11,
    reference: "1 Thessalonians 5:11",
    text: "Therefore encourage one another and build each other up, just as in fact you are doing.",
  },
  {
    chapter: 27,
    verse: 9,
    reference: "Proverbs 27:9",
    text: "Perfume and incense bring joy to the heart, and the pleasantness of a friend springs from their heartfelt advice.",
  },
  {
    chapter: 15,
    verse: 33,
    reference: "Job 6:14",
    text: "Anyone who withholds kindness from a friend forsakes the fear of the Almighty.",
  },
  {
    chapter: 16,
    verse: 28,
    reference: "Proverbs 16:28",
    text: "A perverse person stirs up conflict, and a gossip separates close friends.",
  },
  {
    chapter: 24,
    verse: 26,
    reference: "Proverbs 24:26",
    text: "An honest answer is like a kiss on the lips.",
  },
  {
    chapter: 5,
    verse: 12,
    reference: "Ecclesiastes 4:12",
    text: "Though one may be overpowered, two can defend themselves. A cord of three strands is not quickly broken.",
  },
  {
    chapter: 10,
    verse: 25,
    reference: "Hebrews 10:24-25",
    text: "And let us consider how we may spur one another on toward love and good deeds, not giving up meeting together, as some are in the habit of doing, but encouraging one another—and all the more as you see the Day approaching.",
  },
  {
    chapter: 27,
    verse: 6,
    reference: "Proverbs 27:6",
    text: "Wounds from a friend can be trusted, but an enemy multiplies kisses.",
  },
  {
    chapter: 11,
    verse: 25,
    reference: "Proverbs 11:25",
    text: "A generous person will prosper; whoever refreshes others will be refreshed.",
  },
  {
    chapter: 15,
    verse: 1,
    reference: "Romans 12:10",
    text: "Be devoted to one another in love. Honor one another above yourselves.",
  },
  {
    chapter: 15,
    verse: 33,
    reference: "1 Corinthians 15:33",
    text: "Do not be misled: 'Bad company corrupts good character.'",
  },
  {
    chapter: 17,
    verse: 17,
    reference: "Proverbs 17:9",
    text: "Whoever would foster love covers over an offense, but whoever repeats the matter separates close friends.",
  },
  {
    chapter: 6,
    verse: 35,
    reference: "Luke 6:31",
    text: "Do to others as you would have them do to you.",
  },
  {
    chapter: 12,
    verse: 9,
    reference: "Romans 12:9",
    text: "Love must be sincere. Hate what is evil; cling to what is good.",
  },
  {
    chapter: 19,
    verse: 20,
    reference: "Proverbs 19:20",
    text: "Listen to advice and accept discipline, and at the end you will be counted among the wise.",
  },
];
