import React, { useState } from "react";
import { TOPIC_COLORS } from "../constants/topicColors";

import { BIBLE_TOPIC_VERSES } from "../constants/topicModules";

const BibleSearch: React.FC = () => {
  // const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<{
    reference: string;
    verses: {
      chapter: number;
      verse: number;
      text: string;
      reference: string;
    }[];
    translation: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Handle quick filter click
  const handleQuickFilter = (topic: string) => {
    setResults({
      reference: topic,
      verses: BIBLE_TOPIC_VERSES[topic].map((verse) => ({
        chapter: verse.chapter,
        verse: verse.verse,
        text: verse.text,
        reference: verse.reference,
      })),
      translation: "NIV",
    });
    setError(null);
  };

  return (
    <div className="p-6 bg-black bg-opacity-60 shadow-lg mx-auto">
      <h2 className="mt-5 text-2xl md:text-3xl font-bold text-white uppercase mb-8 text-center">
        Quick Bible Search Tool (NIV)
      </h2>

      <p className="text-white text-lg text-center p-1 mb-4">
        Search for quick filters below for specific bible verses on topics. This
        quick search uses the (NIV) translation.
      </p>

      {/* Quick Filters */}
      <div className="mb-4 flex flex-wrap gap-2 justify-center">
        {Object.keys(BIBLE_TOPIC_VERSES).map((topic) => (
          <button
            key={topic}
            onClick={() => handleQuickFilter(topic)}
            className={`text-white py-2 px-4 transition uppercase font-bold ${
              TOPIC_COLORS[topic] || "bg-gray-600 hover:bg-gray-700"
            }`}
          >
            {topic}
          </button>
        ))}
      </div>

      <div className="p-6 bg-black bg-opacity-30 shadow-lg max-w-4xl mx-auto mx-auto">
        {/* Bible Quick Verse Search Section */}
        <div>
          {loading && <p className="text-white">Searching...</p>}
          {error && <p className="text-red-500">{error}</p>}
        </div>

        {/* Results Section */}
        <div className="bg-gray-100 p-4 rounded-lg border border-gray-300 shadow-inner">
          {results ? (
            <div className="text-gray-700 overflow-y-auto max-h-96">
              <h3 className="text-gray-800 text-xl font-bold mb-4">
                {results.reference} ({results.translation})
              </h3>
              <ul>
                {results.verses.map((verse, index) => (
                  <li key={`${verse.reference}-${index}`} className="text-xl mb-2">
                    <strong>{verse.reference}</strong>: {verse.text}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p className="text-gray-500 italic">
              No results to display. Use the quick filter to search for a Bible verse according to a topic.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BibleSearch;
