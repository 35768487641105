import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { BlogPost } from "../types/types";
import BlogList from "./Blog/BlogList";
import Loader from "./Loader";
import backgroundImage from "../assets/bible.png";
import { metaTags } from "../constants/metadata";
import Header from "./Header";
import BibleVerses from "./BibleVerses";
import Footer from "./Footer/Footer";
import BibleSearch from "./BibleSearch";
import bibleBackground from "../assets/bible.png";
import { fetchBlogPosts } from "../api/blogApi";
import BibleStudyFlipBooks from "./BibleStudyFlipBooks";
import YouVersionBible from "./YouVersionBible";
import CarratereSVG from "./FontGraphics/Carrratere";
import natureGradient from "../assets/nature-gradient.png";
import HomeIntro from "./HomeIntro";
import { useScrollToSection } from "../hooks/useScrollToSection";
import MediaSection from "./MediaSection";
import { mediaItems } from "../constants/MediaItems";
import FetchBible from "./FetchBible";
import BibleChatGPT from "./BibleChatGPT";

const Home: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Section refs for smooth scrolling
  const homeIntroRef = useRef<HTMLDivElement>(null);
  const bibleVersesRef = useRef<HTMLDivElement>(null);
  const blogListRef = useRef<HTMLDivElement>(null);
  const bibleSearchRef = useRef<HTMLDivElement>(null);
  const flipBooksRef = useRef<HTMLDivElement>(null);
  const bibleAppRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  // Hook for precise navigation
  const scrollToSection = useScrollToSection({
    homeIntroRef,
    bibleVersesRef,
    blogListRef,
    bibleSearchRef,
    flipBooksRef,
    bibleAppRef,
    mediaRef,
    footerRef,
  });

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const posts = await fetchBlogPosts();
        setBlogPosts(posts);
      } catch (err) {
        setError("An error occurred while fetching blog posts");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Scroll to the specified section when returning to the homepage
    if (location.state?.section) {
      setTimeout(() => {
        scrollToSection(location.state.section); // Trigger precise scroll
      }, 100); // Delay to allow DOM rendering
    }
  }, [location.state, scrollToSection]);

  return (
    <>
      <Header scrollToSection={scrollToSection} />
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
        <meta name="keywords" content={metaTags.keywords} />
        <meta name="author" content={metaTags.author} />
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
        <meta property="og:url" content={metaTags.url} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={metaTags.image} />
        <script type="application/ld+json">{metaTags.structuredData}</script>
      </Helmet>

      {/* Home Intro Section */}
      <div
        ref={homeIntroRef}
        style={{
          backgroundImage: `url(${natureGradient})`,
          backgroundAttachment: "fixed",
          backgroundPositionX: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        }}
        className="inset-0 bg-black bg-opacity-80"
      >
        <div className="w-full max-w-[600px] md:mx-auto text-left">
          <CarratereSVG className="w-full h-auto mt-10" />
        </div>
        <div className="bg-black bg-opacity-20 p-5 md:p-0 border-t-2 border-y-2">
          <div className="container mx-auto">
            <HomeIntro />
          </div>
        </div>
      </div>

      {/* Bible Verses Section */}
      <div ref={bibleVersesRef}>
        <BibleVerses />
      </div>

      {/* Blog Section */}
      <div
        ref={blogListRef}
        className="bg-cover bg-center bg-fixed min-h-screen"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="bg-black bg-opacity-50 min-h-screen p-5 md:p-0 border-b-2">
          <div className="container mx-auto py-10">
            {isLoading ? (
              <Loader />
            ) : error ? (
              <p className="text-red-500 text-center">{error}</p>
            ) : (
              <BlogList blogPosts={blogPosts} />
            )}
          </div>
        </div>
      </div>

      {/* Bible Search and Flipbooks Section */}
      <div
        ref={bibleSearchRef}
        className="md:p-20 inset-0 z-0"
        style={{
          backgroundImage: `url(${bibleBackground})`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      >
        <BibleSearch />
        <div ref={flipBooksRef}>
          <BibleStudyFlipBooks />
        </div>
        {/* <YouVersionBible /> */}
        <div
          className="lg:px-6 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2"
          ref={bibleAppRef}
        >
          <FetchBible />
          <BibleChatGPT />
        </div>
      </div>

      <div
        ref={mediaRef}
        className="bg-cover bg-center bg-fixed min-h-screen"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <MediaSection mediaItems={mediaItems} />
      </div>

      {/* Footer Section */}
      <div ref={footerRef}>
        <Footer />
      </div>
    </>
  );
};

export default Home;
