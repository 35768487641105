import axios from "axios";

export const getBibleChatResponse = async (message: string) => {
  try {
    const response = await axios.post(
      "https://api-inference.huggingface.co/models/mistralai/Mistral-7B-Instruct-v0.2",
      {
        inputs: `[INST] As a Bible scholar, ${message} [/INST]`,
        parameters: {
          max_new_tokens: 1000,
          temperature: 0.7,
          top_p: 0.9,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_HUGGINGFACE_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data && response.data[0]?.generated_text) {
      // Clean up the response to remove [INST] tags
      const cleanedResponse = response.data[0].generated_text.replace(
        /\[INST\][\s\S]*?\[\/INST\]/g,
        ""
      );
      return { response: cleanedResponse.trim() };
    } else {
      throw new Error("No response generated from the model");
    }
  } catch (error) {
    console.error("Error details:", error);
    throw new Error("An error occurred while processing your request");
  }
};
