import { AngerVerses } from "./anger";
import { AnxietyVerses } from "./anxiety";
import { ComfortVerses } from "./comfort";
import { DepressionVerses } from "./depression";
import { EnvyVerses } from "./envy";
import { FearVerses } from "./fear";
import { FriendshipVerses } from "./friendship";
import { GreedVerses } from "./greed";
import { GuidanceVerses } from "./guidance";
import { HealingVerses } from "./healing";
import { HopeVerses } from "./hope";
import { JoyVerses } from "./joyVerses";
import { LoveVerses } from "./love";
import { PatienceVerses } from "./patience";
import { PeaceVerses } from "./peace";
import { SalvationVerses } from "./salvation";
import { SinVerses } from "./sin";
import { StrengthVerses } from "./strength";
import { TrustVerses } from "./trust";
import { WisdomVerses } from "./wisdom";

interface IBibleTopicVerses {
  chapter: number;
  verse: number;
  reference: string;
  text: string;
}

export const BIBLE_TOPIC_VERSES: { [key: string]: IBibleTopicVerses[] } = {
  Anxiety: AnxietyVerses,
  Comfort: ComfortVerses,
  Depression: DepressionVerses,
  Fear: FearVerses,
  Anger: AngerVerses,
  FriendShip: FriendshipVerses,
  Guidance: GuidanceVerses,
  Healing: HealingVerses,
  Hope: HopeVerses,
  Joy: JoyVerses,
  Love: LoveVerses,
  Patience: PatienceVerses,
  Peace: PeaceVerses,
  Salvation: SalvationVerses,
  Sin: SinVerses,
  Strength: StrengthVerses,
  Trust: TrustVerses,
  Wisdom: WisdomVerses,
  Greed: GreedVerses,
  Envy: EnvyVerses,
};
