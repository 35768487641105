export const PeaceVerses = [
  {
    chapter: 14,
    verse: 27,
    reference: "John 14:27",
    text: "Peace I leave with you; my peace I give you. I do not give to you as the world gives. Do not let your hearts be troubled and do not be afraid.",
  },
  {
    chapter: 16,
    verse: 33,
    reference: "John 16:33",
    text: "I have told you these things, so that in me you may have peace. In this world you will have trouble. But take heart! I have overcome the world.",
  },
  {
    chapter: 4,
    verse: 6,
    reference: "Philippians 4:6-7",
    text: "Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God. And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
  },
  {
    chapter: 23,
    verse: 1,
    reference: "Psalm 23:1-3",
    text: "The Lord is my shepherd, I lack nothing. He makes me lie down in green pastures, he leads me beside quiet waters, he refreshes my soul.",
  },
  {
    chapter: 9,
    verse: 6,
    reference: "Isaiah 9:6",
    text: "For to us a child is born, to us a son is given, and the government will be on his shoulders. And he will be called Wonderful Counselor, Mighty God, Everlasting Father, Prince of Peace.",
  },
  {
    chapter: 26,
    verse: 3,
    reference: "Isaiah 26:3",
    text: "You will keep in perfect peace those whose minds are steadfast, because they trust in you.",
  },
  {
    chapter: 55,
    verse: 12,
    reference: "Isaiah 55:12",
    text: "You will go out in joy and be led forth in peace; the mountains and hills will burst into song before you, and all the trees of the field will clap their hands.",
  },
  {
    chapter: 3,
    verse: 16,
    reference: "Colossians 3:15",
    text: "Let the peace of Christ rule in your hearts, since as members of one body you were called to peace. And be thankful.",
  },
  {
    chapter: 15,
    verse: 13,
    reference: "Romans 15:13",
    text: "May the God of hope fill you with all joy and peace as you trust in him, so that you may overflow with hope by the power of the Holy Spirit.",
  },
  {
    chapter: 5,
    verse: 1,
    reference: "Romans 5:1",
    text: "Therefore, since we have been justified through faith, we have peace with God through our Lord Jesus Christ.",
  },
  {
    chapter: 8,
    verse: 6,
    reference: "Romans 8:6",
    text: "The mind governed by the flesh is death, but the mind governed by the Spirit is life and peace.",
  },
  {
    chapter: 6,
    verse: 24,
    reference: "Numbers 6:24-26",
    text: "The Lord bless you and keep you; the Lord make his face shine on you and be gracious to you; the Lord turn his face toward you and give you peace.",
  },
  {
    chapter: 34,
    verse: 14,
    reference: "Psalm 34:14",
    text: "Turn from evil and do good; seek peace and pursue it.",
  },
  {
    chapter: 85,
    verse: 8,
    reference: "Psalm 85:8",
    text: "I will listen to what God the Lord says; he promises peace to his people, his faithful servants—but let them not turn to folly.",
  },
  {
    chapter: 119,
    verse: 165,
    reference: "Psalm 119:165",
    text: "Great peace have those who love your law, and nothing can make them stumble.",
  },
  {
    chapter: 12,
    verse: 18,
    reference: "Romans 12:18",
    text: "If it is possible, as far as it depends on you, live at peace with everyone.",
  },
  {
    chapter: 1,
    verse: 7,
    reference: "2 Timothy 1:7",
    text: "For the Spirit God gave us does not make us timid, but gives us power, love and self-discipline.",
  },
  {
    chapter: 14,
    verse: 17,
    reference: "Romans 14:17",
    text: "For the kingdom of God is not a matter of eating and drinking, but of righteousness, peace and joy in the Holy Spirit.",
  },
  {
    chapter: 48,
    verse: 18,
    reference: "Isaiah 48:18",
    text: "If only you had paid attention to my commands, your peace would have been like a river, your well-being like the waves of the sea.",
  },
  {
    chapter: 55,
    verse: 22,
    reference: "Psalm 55:22",
    text: "Cast your cares on the Lord and he will sustain you; he will never let the righteous be shaken.",
  },
  {
    chapter: 20,
    verse: 20,
    reference: "John 20:21",
    text: "Again Jesus said, 'Peace be with you! As the Father has sent me, I am sending you.'",
  },
  {
    chapter: 4,
    verse: 7,
    reference: "Philippians 4:7",
    text: "And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
  },
  {
    chapter: 3,
    verse: 1,
    reference: "2 Thessalonians 3:16",
    text: "Now may the Lord of peace himself give you peace at all times and in every way. The Lord be with all of you.",
  },
  {
    chapter: 3,
    verse: 9,
    reference: "Proverbs 3:9",
    text: "Her ways are pleasant ways, and all her paths are peace.",
  },
];
