// Mapping of topic names to button colors
export const TOPIC_COLORS: Record<string, string> = {
  Anxiety: "bg-red-600 hover:bg-red-700",
  Fear: "bg-yellow-500 hover:bg-yellow-600",
  Depression: "bg-gray-500 hover:bg-gray-600",
  Anger: "bg-orange-600 hover:bg-orange-700",
  Sin: "bg-purple-600 hover:bg-purple-700",
  Love: "bg-pink-600 hover:bg-pink-700",
  Healing: "bg-green-600 hover:bg-green-700",
  Hope: "bg-teal-600 hover:bg-teal-700",
  Patience: "bg-cyan-600 hover:bg-cyan-700",
  Strength: "bg-blue-600 hover:bg-blue-700",
  Peace: "bg-indigo-600 hover:bg-indigo-700",
  Joy: "bg-emerald-600 hover:bg-emerald-700",
  Wisdom: "bg-lime-600 hover:bg-lime-700",
  Guidance: "bg-sky-600 hover:bg-sky-700",
  Salvation: "bg-violet-600 hover:bg-violet-700",
  Comfort: "bg-rose-600 hover:bg-rose-700",
  Trust: "bg-amber-600 hover:bg-amber-700",
};
