export const StrengthVerses = [
  {
    chapter: 6,
    verse: 10,
    reference: "Ephesians 6:10",
    text: "Finally, be strong in the Lord and in his mighty power.",
  },
  {
    chapter: 4,
    verse: 13,
    reference: "Philippians 4:13",
    text: "I can do all this through him who gives me strength.",
  },
  {
    chapter: 29,
    verse: 11,
    reference: "Jeremiah 29:11",
    text: "For I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to give you hope and a future.",
  },
  {
    chapter: 40,
    verse: 31,
    reference: "Isaiah 40:31",
    text: "But those who hope in the Lord will renew their strength. They will soar on wings like eagles; they will run and not grow weary, they will walk and not be faint.",
  },
  {
    chapter: 41,
    verse: 10,
    reference: "Isaiah 41:10",
    text: "So do not fear, for I am with you; do not be dismayed, for I am your God. I will strengthen you and help you; I will uphold you with my righteous right hand.",
  },
  {
    chapter: 18,
    verse: 2,
    reference: "Psalm 18:2",
    text: "The Lord is my rock, my fortress and my deliverer; my God is my rock, in whom I take refuge, my shield and the horn of my salvation, my stronghold.",
  },
  {
    chapter: 28,
    verse: 7,
    reference: "Psalm 28:7",
    text: "The Lord is my strength and my shield; my heart trusts in him, and he helps me. My heart leaps for joy, and with my song I praise him.",
  },
  {
    chapter: 46,
    verse: 1,
    reference: "Psalm 46:1",
    text: "God is our refuge and strength, an ever-present help in trouble.",
  },
  {
    chapter: 27,
    verse: 1,
    reference: "Psalm 27:1",
    text: "The Lord is my light and my salvation—whom shall I fear? The Lord is the stronghold of my life—of whom shall I be afraid?",
  },
  {
    chapter: 73,
    verse: 26,
    reference: "Psalm 73:26",
    text: "My flesh and my heart may fail, but God is the strength of my heart and my portion forever.",
  },
  {
    chapter: 3,
    verse: 19,
    reference: "Habakkuk 3:19",
    text: "The Sovereign Lord is my strength; he makes my feet like the feet of a deer, he enables me to tread on the heights.",
  },
  {
    chapter: 8,
    verse: 10,
    reference: "Nehemiah 8:10",
    text: "Nehemiah said, 'Go and enjoy choice food and sweet drinks, and send some to those who have nothing prepared. This day is holy to our Lord. Do not grieve, for the joy of the Lord is your strength.'",
  },
  {
    chapter: 19,
    verse: 14,
    reference: "Exodus 15:2",
    text: "The Lord is my strength and my defense; he has become my salvation. He is my God, and I will praise him, my father’s God, and I will exalt him.",
  },
  {
    chapter: 5,
    verse: 7,
    reference: "Deuteronomy 31:6",
    text: "Be strong and courageous. Do not be afraid or terrified because of them, for the Lord your God goes with you; he will never leave you nor forsake you.",
  },
  {
    chapter: 2,
    verse: 1,
    reference: "2 Timothy 2:1",
    text: "You then, my son, be strong in the grace that is in Christ Jesus.",
  },
  {
    chapter: 16,
    verse: 13,
    reference: "1 Corinthians 16:13",
    text: "Be on your guard; stand firm in the faith; be courageous; be strong.",
  },
  {
    chapter: 12,
    verse: 9,
    reference: "2 Corinthians 12:9",
    text: "But he said to me, 'My grace is sufficient for you, for my power is made perfect in weakness.' Therefore I will boast all the more gladly about my weaknesses, so that Christ’s power may rest on me.",
  },
  {
    chapter: 4,
    verse: 1,
    reference: "Isaiah 40:29",
    text: "He gives strength to the weary and increases the power of the weak.",
  },
  {
    chapter: 24,
    verse: 10,
    reference: "Proverbs 24:10",
    text: "If you falter in a time of trouble, how small is your strength!",
  },
  {
    chapter: 22,
    verse: 18,
    reference: "Psalm 22:19",
    text: "But you, Lord, do not be far from me. You are my strength; come quickly to help me.",
  },
  {
    chapter: 6,
    verse: 33,
    reference: "Matthew 6:33",
    text: "But seek first his kingdom and his righteousness, and all these things will be given to you as well.",
  },
  {
    chapter: 5,
    verse: 7,
    reference: "Joshua 1:9",
    text: "Have I not commanded you? Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go.",
  },
  {
    chapter: 18,
    verse: 32,
    reference: "2 Samuel 22:33",
    text: "It is God who arms me with strength and keeps my way secure.",
  },
  {
    chapter: 22,
    verse: 13,
    reference: "Psalm 22:13",
    text: "The Lord is my strength and my song; he has become my salvation.",
  },
];
