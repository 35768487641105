export const GuidanceVerses = [
  {
    chapter: 32,
    verse: 8,
    reference: "Isaiah 32:8",
    text: "But the noble make noble plans, and by noble deeds they stand.",
  },
  {
    chapter: 119,
    verse: 105,
    reference: "Psalm 119:105",
    text: "Your word is a lamp for my feet, a light on my path.",
  },
  {
    chapter: 3,
    verse: 5,
    reference: "Proverbs 3:5-6",
    text: "Trust in the Lord with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight.",
  },
  {
    chapter: 12,
    verse: 2,
    reference: "Romans 12:2",
    text: "Do not conform to the pattern of this world, but be transformed by the renewing of your mind. Then you will be able to test and approve what God’s will is—his good, pleasing and perfect will.",
  },
  {
    chapter: 1,
    verse: 5,
    reference: "James 1:5",
    text: "If any of you lacks wisdom, you should ask God, who gives generously to all without finding fault, and it will be given to you.",
  },
  {
    chapter: 37,
    verse: 23,
    reference: "Psalm 37:23",
    text: "The Lord makes firm the steps of the one who delights in him.",
  },
  {
    chapter: 11,
    verse: 14,
    reference: "Proverbs 11:14",
    text: "For lack of guidance a nation falls, but victory is won through many advisers.",
  },
  {
    chapter: 2,
    verse: 6,
    reference: "Proverbs 2:6",
    text: "For the Lord gives wisdom; from his mouth come knowledge and understanding.",
  },
  {
    chapter: 29,
    verse: 18,
    reference: "Proverbs 29:18",
    text: "Where there is no revelation, people cast off restraint; but blessed is the one who heeds wisdom’s instruction.",
  },
  {
    chapter: 14,
    verse: 12,
    reference: "Proverbs 14:12",
    text: "There is a way that appears to be right, but in the end it leads to death.",
  },
  {
    chapter: 16,
    verse: 9,
    reference: "Proverbs 16:9",
    text: "In their hearts humans plan their course, but the Lord establishes their steps.",
  },
  {
    chapter: 18,
    verse: 30,
    reference: "Psalm 18:30",
    text: "As for God, his way is perfect: The Lord’s word is flawless; he shields all who take refuge in him.",
  },
  {
    chapter: 2,
    verse: 10,
    reference: "Ephesians 2:10",
    text: "For we are God’s handiwork, created in Christ Jesus to do good works, which God prepared in advance for us to do.",
  },
  {
    chapter: 5,
    verse: 16,
    reference: "Galatians 5:16",
    text: "So I say, walk by the Spirit, and you will not gratify the desires of the flesh.",
  },
  {
    chapter: 32,
    verse: 8,
    reference: "Psalm 32:8",
    text: "I will instruct you and teach you in the way you should go; I will counsel you with my loving eye on you.",
  },
  {
    chapter: 3,
    verse: 6,
    reference: "Ecclesiastes 3:1",
    text: "There is a time for everything, and a season for every activity under the heavens.",
  },
  {
    chapter: 1,
    verse: 9,
    reference: "Joshua 1:9",
    text: "Have I not commanded you? Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go.",
  },
  {
    chapter: 55,
    verse: 22,
    reference: "Psalm 55:22",
    text: "Cast your cares on the Lord and he will sustain you; he will never let the righteous be shaken.",
  },
  {
    chapter: 33,
    verse: 3,
    reference: "Jeremiah 33:3",
    text: "Call to me and I will answer you and tell you great and unsearchable things you do not know.",
  },
  {
    chapter: 29,
    verse: 11,
    reference: "Jeremiah 29:11",
    text: "For I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to give you hope and a future.",
  },
  {
    chapter: 8,
    verse: 28,
    reference: "Romans 8:28",
    text: "And we know that in all things God works for the good of those who love him, who have been called according to his purpose.",
  },
  {
    chapter: 2,
    verse: 13,
    reference: "Philippians 2:13",
    text: "For it is God who works in you to will and to act in order to fulfill his good purpose.",
  },
  {
    chapter: 6,
    verse: 14,
    reference: "Isaiah 30:21",
    text: "Whether you turn to the right or to the left, your ears will hear a voice behind you, saying, 'This is the way; walk in it.'",
  },
  {
    chapter: 19,
    verse: 21,
    reference: "Proverbs 19:21",
    text: "Many are the plans in a person’s heart, but it is the Lord’s purpose that prevails.",
  },
];
