export const TrustVerses = [
  {
    chapter: 3,
    verse: 5,
    reference: "Proverbs 3:5-6",
    text: "Trust in the Lord with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight.",
  },
  {
    chapter: 13,
    verse: 5,
    reference: "Psalm 13:5-6",
    text: "But I trust in your unfailing love; my heart rejoices in your salvation. I will sing the Lord’s praise, for he has been good to me.",
  },
  {
    chapter: 56,
    verse: 3,
    reference: "Psalm 56:3-4",
    text: "When I am afraid, I put my trust in you. In God, whose word I praise—in God I trust and am not afraid. What can mere mortals do to me?",
  },
  {
    chapter: 1,
    verse: 7,
    reference: "Proverbs 1:7",
    text: "The fear of the Lord is the beginning of knowledge, but fools despise wisdom and instruction.",
  },
  {
    chapter: 9,
    verse: 10,
    reference: "Proverbs 9:10",
    text: "The fear of the Lord is the beginning of wisdom, and knowledge of the Holy One is understanding.",
  },
  {
    chapter: 118,
    verse: 8,
    reference: "Psalm 118:8",
    text: "It is better to take refuge in the Lord than to trust in humans.",
  },
  {
    chapter: 4,
    verse: 8,
    reference: "Philippians 4:6-7",
    text: "Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God. And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
  },
  {
    chapter: 91,
    verse: 2,
    reference: "Psalm 91:2",
    text: "I will say of the Lord, 'He is my refuge and my fortress, my God, in whom I trust.'",
  },
  {
    chapter: 37,
    verse: 5,
    reference: "Psalm 37:5",
    text: "Commit your way to the Lord; trust in him and he will do this:",
  },
  {
    chapter: 20,
    verse: 7,
    reference: "Psalm 20:7",
    text: "Some trust in chariots and some in horses, but we trust in the name of the Lord our God.",
  },
  {
    chapter: 28,
    verse: 7,
    reference: "Psalm 28:7",
    text: "The Lord is my strength and my shield; my heart trusts in him, and he helps me. My heart leaps for joy, and with my song I praise him.",
  },
  {
    chapter: 125,
    verse: 1,
    reference: "Psalm 125:1",
    text: "Those who trust in the Lord are like Mount Zion, which cannot be shaken but endures forever.",
  },
  {
    chapter: 32,
    verse: 10,
    reference: "Psalm 32:10",
    text: "Many are the woes of the wicked, but the Lord’s unfailing love surrounds the one who trusts in him.",
  },
  {
    chapter: 26,
    verse: 4,
    reference: "Isaiah 26:4",
    text: "Trust in the Lord forever, for the Lord, the Lord himself, is the Rock eternal.",
  },
  {
    chapter: 30,
    verse: 1,
    reference: "Isaiah 30:15",
    text: "This is what the Sovereign Lord, the Holy One of Israel, says: 'In repentance and rest is your salvation, in quietness and trust is your strength, but you would have none of it.'",
  },
  {
    chapter: 1,
    verse: 9,
    reference: "Nahum 1:7",
    text: "The Lord is good, a refuge in times of trouble. He cares for those who trust in him.",
  },
  {
    chapter: 10,
    verse: 28,
    reference: "Proverbs 10:28",
    text: "The prospect of the righteous is joy, but the hopes of the wicked come to nothing.",
  },
  {
    chapter: 2,
    verse: 8,
    reference: "Ephesians 2:8",
    text: "For it is by grace you have been saved, through faith—and this is not from yourselves, it is the gift of God.",
  },
  {
    chapter: 18,
    verse: 10,
    reference: "Proverbs 18:10",
    text: "The name of the Lord is a fortified tower; the righteous run to it and are safe.",
  },
  {
    chapter: 5,
    verse: 1,
    reference: "Romans 5:1",
    text: "Therefore, since we have been justified through faith, we have peace with God through our Lord Jesus Christ.",
  },
  {
    chapter: 6,
    verse: 19,
    reference: "Hebrews 6:19",
    text: "We have this hope as an anchor for the soul, firm and secure. It enters the inner sanctuary behind the curtain.",
  },
  {
    chapter: 16,
    verse: 3,
    reference: "Proverbs 16:3",
    text: "Commit to the Lord whatever you do, and he will establish your plans.",
  },
  {
    chapter: 12,
    verse: 2,
    reference: "Romans 12:2",
    text: "Do not conform to the pattern of this world, but be transformed by the renewing of your mind. Then you will be able to test and approve what God’s will is—his good, pleasing and perfect will.",
  },
  {
    chapter: 3,
    verse: 17,
    reference: "Zephaniah 3:17",
    text: "The Lord your God is with you, the Mighty Warrior who saves. He will take great delight in you; in his love he will no longer rebuke you, but will rejoice over you with singing.",
  },
  {
    chapter: 7,
    verse: 14,
    reference: "2 Chronicles 7:14",
    text: "If my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then I will hear from heaven, and I will forgive their sin and will heal their land.",
  },
];
