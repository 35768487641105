export const AngerVerses = [
  {
    chapter: 1,
    verse: 19,
    reference: "James 1:19-20",
    text: "My dear brothers and sisters, take note of this: Everyone should be quick to listen, slow to speak and slow to become angry, because human anger does not produce the righteousness that God desires.",
  },
  {
    chapter: 4,
    verse: 26,
    reference: "Ephesians 4:26",
    text: "In your anger do not sin: Do not let the sun go down while you are still angry,",
  },
  {
    chapter: 4,
    verse: 31,
    reference: "Ephesians 4:31",
    text: "Get rid of all bitterness, rage and anger, brawling and slander, along with every form of malice.",
  },
  {
    chapter: 15,
    verse: 1,
    reference: "Proverbs 15:1",
    text: "A gentle answer turns away wrath, but a harsh word stirs up anger.",
  },
  {
    chapter: 29,
    verse: 11,
    reference: "Proverbs 29:11",
    text: "Fools give full vent to their rage, but the wise bring calm in the end.",
  },
  {
    chapter: 16,
    verse: 32,
    reference: "Proverbs 16:32",
    text: "Better a patient person than a warrior, one with self-control than one who takes a city.",
  },
  {
    chapter: 14,
    verse: 29,
    reference: "Proverbs 14:29",
    text: "Whoever is patient has great understanding, but one who is quick-tempered displays folly.",
  },
  {
    chapter: 37,
    verse: 8,
    reference: "Psalm 37:8",
    text: "Refrain from anger and turn from wrath; do not fret—it leads only to evil.",
  },
  {
    chapter: 3,
    verse: 8,
    reference: "Colossians 3:8",
    text: "But now you must also rid yourselves of all such things as these: anger, rage, malice, slander, and filthy language from your lips.",
  },
  {
    chapter: 12,
    verse: 17,
    reference: "Romans 12:17-19",
    text: "Do not repay anyone evil for evil. Be careful to do what is right in the eyes of everyone. If it is possible, as far as it depends on you, live at peace with everyone. Do not take revenge, my dear friends, but leave room for God’s wrath, for it is written: 'It is mine to avenge; I will repay,' says the Lord.",
  },
  {
    chapter: 14,
    verse: 17,
    reference: "Proverbs 14:17",
    text: "A quick-tempered person does foolish things, and the one who devises evil schemes is hated.",
  },
  {
    chapter: 19,
    verse: 11,
    reference: "Proverbs 19:11",
    text: "A person’s wisdom yields patience; it is to one’s glory to overlook an offense.",
  },
  {
    chapter: 6,
    verse: 27,
    reference: "Matthew 5:22",
    text: "But I tell you that anyone who is angry with a brother or sister will be subject to judgment. Again, anyone who says to a brother or sister, 'Raca,' is answerable to the court. And anyone who says, 'You fool!' will be in danger of the fire of hell.",
  },
  {
    chapter: 10,
    verse: 19,
    reference: "Proverbs 10:19",
    text: "Sin is not ended by multiplying words, but the prudent hold their tongues.",
  },
  {
    chapter: 11,
    verse: 29,
    reference: "Proverbs 11:29",
    text: "Whoever brings ruin on their family will inherit only wind, and the fool will be servant to the wise.",
  },
  {
    chapter: 25,
    verse: 28,
    reference: "Proverbs 25:28",
    text: "Like a city whose walls are broken through is a person who lacks self-control.",
  },
  {
    chapter: 29,
    verse: 22,
    reference: "Proverbs 29:22",
    text: "An angry person stirs up conflict, and a hot-tempered person commits many sins.",
  },
  {
    chapter: 7,
    verse: 9,
    reference: "Ecclesiastes 7:9",
    text: "Do not be quickly provoked in your spirit, for anger resides in the lap of fools.",
  },
  {
    chapter: 24,
    verse: 29,
    reference: "Proverbs 24:29",
    text: "Do not say, 'I’ll do to them as they have done to me; I’ll pay them back for what they did.'",
  },
  {
    chapter: 6,
    verse: 27,
    reference: "Matthew 6:14-15",
    text: "For if you forgive other people when they sin against you, your heavenly Father will also forgive you. But if you do not forgive others their sins, your Father will not forgive your sins.",
  },
  {
    chapter: 14,
    verse: 29,
    reference: "Proverbs 14:29",
    text: "Whoever is patient has great understanding, but one who is quick-tempered displays folly.",
  },
  {
    chapter: 18,
    verse: 21,
    reference: "Proverbs 18:21",
    text: "The tongue has the power of life and death, and those who love it will eat its fruit.",
  },
  {
    chapter: 14,
    verse: 16,
    reference: "Psalm 141:3",
    text: "Set a guard over my mouth, LORD; keep watch over the door of my lips.",
  },
  {
    chapter: 20,
    verse: 22,
    reference: "Proverbs 20:22",
    text: "Do not say, 'I’ll pay you back for this wrong!' Wait for the LORD, and he will avenge you.",
  },
  {
    chapter: 14,
    verse: 16,
    reference: "Psalm 141:4",
    text: "Do not let my heart be drawn to what is evil so that I take part in wicked deeds along with those who are evildoers; do not let me eat their delicacies.",
  },
];
