export const FavoriteBibleVerseLeft = [
  {
    reference: "Isaiah 53:3 (NIV)",
    verse: `"He was despised and rejected by mankind,
    a man of suffering, and familiar with pain.
Like one from whom people hide their faces
    he was despised, and we held him in low esteem."`,
  },
  {
    reference: "Ecclesiastes 8:7 (KJV)",
    verse: `"Go thy way, eat thy bread with joy, and drink thy wine with a merry heart; for God now accepteth thy works."`,
  },
  {
    reference: "Isaiah 41:10 (KJV)",
    verse: `"Fear thou not; for I am with thee: be not dismayed; for I am thy God: I will strengthen thee; yea, I will help thee; yea, I will uphold thee with the right hand of my righteousness."`,
  },
  {
    reference: "Colossians 3:19 (KJV)",
    verse: `"Husbands, love your wives, and be not bitter against them."`,
  },
  {
    reference: "Ecclesiastes 3:1 (KJV)",
    verse: `"To every thing there is a season, and a time to every purpose under the heaven."`,
  },
  {
    reference: "John 3:16 (KJV)",
    verse: `"For God so loved the world, that he gave his only begotten Son, that whosoever believeth in him should not perish, but have everlasting life."`,
  },
  {
    reference: "Matthew 6:33 (KJV)",
    verse: `"But seek ye first the kingdom of God, and his righteousness, and all these things shall be added unto you."`,
  },
  {
    reference: "Ephesians 6:2 (KJV)",
    verse: `"Honor thy father and mother; which is the first commandment with promise."`,
  },
  {
    reference: "Philippians 4:13 (KJV)",
    verse: `"I can do all things through Christ which strengtheneth me."`,
  },
  {
    reference: "Colossians 3:23 (KJV)",
    verse: `"And whatsoever ye do, do it heartily, as to the Lord, and not unto men."`,
  },
  {
    reference: "Isaiah 43:18-19",
    verse: `"Forget the former things; do not dwell on the past. See, I am doing a new thing! Now it springs up; do you not perceive it? I am making a way in the wilderness and streams in the wasteland."`,
  },
];
