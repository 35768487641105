interface IframeViewerProps {
  url: string;
  title?: string;
  onClose: () => void;
  hideCloseButton?: boolean;
  style?: React.CSSProperties;
}

const IframeViewer: React.FC<IframeViewerProps> = ({
  url,
  title = "Iframe Viewer",
}) => {
  return (
    <div style={{ height: "90vh", position: "relative" }}>
      {" "}
      {/* Increased height */}
      <iframe
        src={url}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title={title}
      ></iframe>
    </div>
  );
};

export default IframeViewer;
