import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import livingWithJesusLogo from "../assets/lifeforjesuschristlogo.png";
import newLogo from "../assets/newlogo.png";

interface HeaderProps {
  scrollToSection?: (section: string) => void;
}

const Header: React.FC<HeaderProps> = ({ scrollToSection }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (section: string) => {
    if (scrollToSection) {
      scrollToSection(section);
    } else {
      navigate("/", { state: { section } });
    }
    setIsMenuOpen(false); // Close the menu after navigation
  };

  // Reset menu state on route changes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <header className="bg-gray-900 bg-opacity-90 shadow-md fixed w-full z-50">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Left - Logo Section */}
        <div className="flex items-center space-x-3 md:flex-col lg:flex-row md:space-x-0 md:space-y-2 lg:space-y-0">
          <img
            src={newLogo}
            alt="Live For Jesus Christ"
            className="w-20 h-20 rounded-full border-2 border-white"
          />
          <img
            src={livingWithJesusLogo}
            alt="Living With Jesus Logo"
            className="h-10 md:h-14"
          />
        </div>

        {/* Right - Hamburger Button */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white xl:hidden focus:outline-none"
        >
          {isMenuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          )}
        </button>

        {/* Fullscreen Navigation Menu */}
        <nav
          className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-95 flex flex-col items-center justify-center z-[60] transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } xl:static xl:translate-x-0 xl:flex-row xl:justify-end xl:space-x-8 xl:bg-transparent xl:h-auto`}
        >
          {/* Close Button for Mobile */}
          <button
            className="absolute top-4 right-4 text-white text-3xl xl:hidden focus:outline-none"
            onClick={() => setIsMenuOpen(false)}
          >
            &times;
          </button>

          {[
            { section: "homeIntro", label: "HOME" },
            { section: "bibleVerses", label: "FAV BIBLE VERSES" },
            { section: "blogList", label: "ARTICLES" },
            { section: "bibleSearch", label: "  QUICK BIBLE VERSE TOOL" },
            { section: "flipBooks", label: "BIBLE RESOURCES" },
            { section: "bibleApp", label: "BIBLE APP" },
            { section: "media", label: "MEDIA" },
            { section: "footer", label: "CONTACT" },
          ].map(({ section, label }) => (
            <button
              key={section}
              onClick={() => handleNavigation(section)}
              className="block text-white font-bold text-2xl py-3 hover:text-blue-400 transition duration-300 xl:inline xl:text-sm xl:py-0"
            >
              {label}
            </button>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
