export const SinVerses = [
  {
    chapter: 1,
    verse: 9,
    reference: "1 John 1:9",
    text: "If we confess our sins, he is faithful and just and will forgive us our sins and purify us from all unrighteousness.",
  },
  {
    chapter: 3,
    verse: 9,
    reference: "1 John 3:9",
    text: "No one who is born of God will continue to sin, because God’s seed remains in them; they cannot go on sinning, because they have been born of God.",
  },
  {
    chapter: 5,
    verse: 17,
    reference: "James 5:17",
    text: "Therefore, to one who knows the right thing to do and does not do it, to him it is sin.",
  },
  {
    chapter: 6,
    verse: 23,
    reference: "Romans 6:23",
    text: "For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.",
  },
  {
    chapter: 3,
    verse: 23,
    reference: "Romans 3:23",
    text: "For all have sinned and fall short of the glory of God.",
  },
  {
    chapter: 5,
    verse: 12,
    reference: "Romans 5:12",
    text: "Therefore, just as sin entered the world through one man, and death through sin, and in this way death came to all people, because all sinned.",
  },
  {
    chapter: 7,
    verse: 20,
    reference: "Romans 7:20",
    text: "Now if I do what I do not want to do, it is no longer I who do it, but it is sin living in me that does it.",
  },
  {
    chapter: 1,
    verse: 15,
    reference: "James 1:15",
    text: "Then, after desire has conceived, it gives birth to sin; and sin, when it is full-grown, gives birth to death.",
  },
  {
    chapter: 4,
    verse: 17,
    reference: "James 4:17",
    text: "If anyone, then, knows the good they ought to do and doesn’t do it, it is sin for them.",
  },
  {
    chapter: 5,
    verse: 19,
    reference: "Galatians 5:19-21",
    text: "The acts of the flesh are obvious: sexual immorality, impurity and debauchery; idolatry and witchcraft; hatred, discord, jealousy, fits of rage, selfish ambition, dissensions, factions and envy; drunkenness, orgies, and the like. I warn you, as I did before, that those who live like this will not inherit the kingdom of God.",
  },
  {
    chapter: 53,
    verse: 6,
    reference: "Isaiah 53:6",
    text: "We all, like sheep, have gone astray, each of us has turned to our own way; and the Lord has laid on him the iniquity of us all.",
  },
  {
    chapter: 14,
    verse: 34,
    reference: "Proverbs 14:34",
    text: "Righteousness exalts a nation, but sin condemns any people.",
  },
  {
    chapter: 15,
    verse: 9,
    reference: "Proverbs 15:9",
    text: "The Lord detests the way of the wicked, but he loves those who pursue righteousness.",
  },
  {
    chapter: 3,
    verse: 8,
    reference: "1 John 3:8",
    text: "The one who does what is sinful is of the devil, because the devil has been sinning from the beginning. The reason the Son of God appeared was to destroy the devil’s work.",
  },
  {
    chapter: 7,
    verse: 20,
    reference: "Ecclesiastes 7:20",
    text: "Indeed, there is no one on earth who is righteous, no one who does what is right and never sins.",
  },
  {
    chapter: 32,
    verse: 5,
    reference: "Psalm 32:5",
    text: "Then I acknowledged my sin to you and did not cover up my iniquity. I said, 'I will confess my transgressions to the Lord.' And you forgave the guilt of my sin.",
  },
  {
    chapter: 15,
    verse: 26,
    reference: "Proverbs 15:26",
    text: "The Lord detests the thoughts of the wicked, but gracious words are pure in his sight.",
  },
  {
    chapter: 5,
    verse: 19,
    reference: "Galatians 5:19",
    text: "The acts of the flesh are obvious: sexual immorality, impurity and debauchery.",
  },
  {
    chapter: 6,
    verse: 1,
    reference: "Galatians 6:1",
    text: "Brothers and sisters, if someone is caught in a sin, you who live by the Spirit should restore that person gently. But watch yourselves, or you also may be tempted.",
  },
  {
    chapter: 4,
    verse: 7,
    reference: "1 John 4:7",
    text: "Dear friends, let us love one another, for love comes from God. Everyone who loves has been born of God and knows God.",
  },
  {
    chapter: 10,
    verse: 26,
    reference: "Hebrews 10:26",
    text: "If we deliberately keep on sinning after we have received the knowledge of the truth, no sacrifice for sins is left.",
  },
  {
    chapter: 1,
    verse: 8,
    reference: "1 John 1:8",
    text: "If we claim to be without sin, we deceive ourselves and the truth is not in us.",
  },
  {
    chapter: 12,
    verse: 14,
    reference: "Hebrews 12:14",
    text: "Make every effort to live in peace with everyone and to be holy; without holiness no one will see the Lord.",
  },
  {
    chapter: 4,
    verse: 9,
    reference: "1 John 4:9",
    text: "This is how God showed his love among us: He sent his one and only Son into the world that we might live through him.",
  },
];
