import React, { useState } from "react";
import { getBibleChatResponse } from "../api/bibleChatService";
import { Message } from "../types/bibleChat";

const examplePrompts = [
  "Who were the twelve apostles?",
  "What is the meaning of the parable of the sower?",
  "What does the Bible say about forgiveness?",
  "Explain the creation story in Genesis.",
  "What is the significance of the Ten Commandments?",
];

const BibleChatGPT = () => {
  const [input, setInput] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (!input.trim()) return;

    setIsLoading(true);
    setError(null);

    const userMessage: Message = { role: "user", content: input };
    setMessages((prev) => [...prev, userMessage]);

    try {
      const data = await getBibleChatResponse(input);
      const assistantMessage: Message = {
        role: "assistant",
        content: data.response,
      };
      setMessages((prev) => [...prev, assistantMessage]);
    } catch (error) {
      console.error("Error:", error);
      setError(error instanceof Error ? error.message : "An error occurred");
    } finally {
      setIsLoading(false);
      setInput("");
    }
  };

  const handlePromptClick = (prompt: string) => {
    setInput(prompt);
    handleSubmit();
  };

  return (
    <div className="max-w-full mx-auto p-4 md:p-6 flex flex-col bg-black bg-opacity-60 shadow-lg">
      <header className="mb-4">
        <h1 className="mt-5 text-2xl md:text-3xl text-white font-bold text-center uppercase">
          Bible Scholar AI Assistant Chat
        </h1>
        <p className="text-white text-lg text-center">
          Ask a question about the Bible and get an AI assistant response.
        </p>
      </header>

      {/* Example Prompts Section */}
      <section className="mb-4 bg-black bg-opacity-40 rounded-lg p-4">
        <h2 className="text-lg text-yellow-300 font-semibold mb-2">
          Example Questions:
        </h2>
        <div className="flex flex-wrap gap-3">
          {examplePrompts.map((prompt, index) => (
            <button
              key={index}
              onClick={() => handlePromptClick(prompt)}
              disabled={isLoading}
              className="bg-gray-700 text-white px-4 py-2 rounded-md text-sm hover:bg-blue-600 transition disabled:bg-gray-600 disabled:cursor-not-allowed"
            >
              {prompt}
            </button>
          ))}
        </div>
      </section>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {/* Chat Messages Section */}
      <div
        className="flex-grow overflow-y-auto bg-black bg-opacity-40 rounded-lg p-4 shadow-md scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800"
        style={{
          minHeight: "300px",
          maxHeight: "500px",
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-4 p-3 rounded-lg max-w-[90%] ${
              message.role === "user"
                ? "ml-auto bg-blue-600 text-white"
                : "mr-auto bg-white text-black"
            }`}
          >
            <p className="text-md md:text-lg whitespace-pre-line">
              {message.content.split("\n").map((paragraph, i) => (
                <React.Fragment key={i}>
                  {paragraph}
                  {i < message.content.split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
          </div>
        ))}
        {isLoading && (
          <div className="text-center text-white py-2">Thinking...</div>
        )}
      </div>

      {/* Input Section */}
      <form
        onSubmit={handleSubmit}
        className="mt-4 flex gap-3 items-center bg-black bg-opacity-40 rounded-lg p-3"
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask a question about the Bible..."
          disabled={isLoading}
          className="flex-grow p-3 border border-gray-700 rounded-md bg-gray-100 text-black
                   placeholder-gray-400 focus:outline-none focus:border-blue-500
                   disabled:bg-gray-400 disabled:cursor-not-allowed text-md md:text-xl"
        />
        <button
          type="submit"
          disabled={isLoading || !input.trim()}
          className="px-6 py-3 bg-blue-600 text-white rounded-md font-medium
                   hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500
                   disabled:bg-gray-600 disabled:cursor-not-allowed transition"
        >
          {isLoading ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
};

export default BibleChatGPT;
