export const EnvyVerses = [
  {
    chapter: 3,
    verse: 16,
    reference: "James 3:16",
    text: "For where you have envy and selfish ambition, there you find disorder and every evil practice.",
  },
  {
    chapter: 14,
    verse: 30,
    reference: "Proverbs 14:30",
    text: "A heart at peace gives life to the body, but envy rots the bones.",
  },
  {
    chapter: 5,
    verse: 21,
    reference: "Galatians 5:21",
    text: "And envy; drunkenness, orgies, and the like. I warn you, as I did before, that those who live like this will not inherit the kingdom of God.",
  },
  {
    chapter: 27,
    verse: 4,
    reference: "Proverbs 27:4",
    text: "Anger is cruel and fury overwhelming, but who can stand before jealousy?",
  },
  {
    chapter: 6,
    verse: 9,
    reference: "Ecclesiastes 6:9",
    text: "Better what the eye sees than the roving of the appetite. This too is meaningless, a chasing after the wind.",
  },
  {
    chapter: 13,
    verse: 14,
    reference: "Job 5:2",
    text: "Resentment kills a fool, and envy slays the simple.",
  },
  {
    chapter: 12,
    verse: 1,
    reference: "Romans 12:1-2",
    text: "Therefore, I urge you, brothers and sisters, in view of God’s mercy, to offer your bodies as a living sacrifice, holy and pleasing to God—this is your true and proper worship. Do not conform to the pattern of this world, but be transformed by the renewing of your mind.",
  },
  {
    chapter: 13,
    verse: 13,
    reference: "Romans 13:13",
    text: "Let us behave decently, as in the daytime, not in carousing and drunkenness, not in sexual immorality and debauchery, not in dissension and jealousy.",
  },
  {
    chapter: 10,
    verse: 12,
    reference: "Proverbs 10:12",
    text: "Hatred stirs up conflict, but love covers over all wrongs.",
  },
  {
    chapter: 73,
    verse: 3,
    reference: "Psalm 73:3",
    text: "For I envied the arrogant when I saw the prosperity of the wicked.",
  },
  {
    chapter: 1,
    verse: 15,
    reference: "Philippians 1:15",
    text: "It is true that some preach Christ out of envy and rivalry, but others out of goodwill.",
  },
  {
    chapter: 6,
    verse: 4,
    reference: "Galatians 6:4",
    text: "Each one should test their own actions. Then they can take pride in themselves alone, without comparing themselves to someone else.",
  },
  {
    chapter: 30,
    verse: 15,
    reference: "Proverbs 30:15-16",
    text: "The leech has two daughters. 'Give! Give!' they cry. There are three things that are never satisfied, four that never say, 'Enough!': the grave, the barren womb, land, which is never satisfied with water, and fire, which never says, 'Enough!'",
  },
  {
    chapter: 4,
    verse: 4,
    reference: "Ecclesiastes 4:4",
    text: "And I saw that all toil and all achievement spring from one person’s envy of another. This too is meaningless, a chasing after the wind.",
  },
  {
    chapter: 16,
    verse: 18,
    reference: "Proverbs 16:18",
    text: "Pride goes before destruction, a haughty spirit before a fall.",
  },
  {
    chapter: 13,
    verse: 40,
    reference: "1 Corinthians 13:4",
    text: "Love is patient, love is kind. It does not envy, it does not boast, it is not proud.",
  },
  {
    chapter: 5,
    verse: 1,
    reference: "1 Peter 2:1",
    text: "Therefore, rid yourselves of all malice and all deceit, hypocrisy, envy, and slander of every kind.",
  },
  {
    chapter: 24,
    verse: 1,
    reference: "Proverbs 24:1",
    text: "Do not envy the wicked, do not desire their company;",
  },
  {
    chapter: 24,
    verse: 19,
    reference: "Proverbs 24:19",
    text: "Do not fret because of evildoers or be envious of the wicked, for the evildoer has no future hope, and the lamp of the wicked will be snuffed out.",
  },
  {
    chapter: 6,
    verse: 11,
    reference: "1 Timothy 6:6-7",
    text: "But godliness with contentment is great gain. For we brought nothing into the world, and we can take nothing out of it.",
  },
  {
    chapter: 3,
    verse: 14,
    reference: "Galatians 3:14",
    text: "He redeemed us in order that the blessing given to Abraham might come to the Gentiles through Christ Jesus, so that by faith we might receive the promise of the Spirit.",
  },
  {
    chapter: 30,
    verse: 8,
    reference: "Proverbs 30:8-9",
    text: "Keep falsehood and lies far from me; give me neither poverty nor riches, but give me only my daily bread. Otherwise, I may have too much and disown you and say, 'Who is the Lord?' Or I may become poor and steal, and so dishonor the name of my God.",
  },
  {
    chapter: 6,
    verse: 7,
    reference: "Ecclesiastes 6:7",
    text: "Everyone’s toil is for their mouth, yet their appetite is never satisfied.",
  },
  {
    chapter: 12,
    verse: 9,
    reference: "Proverbs 12:9",
    text: "Better to be a nobody and yet have a servant than pretend to be somebody and have no food.",
  },
];
