export const AnxietyVerses = [
  {
    chapter: 4,
    verse: 6,
    reference: "Philippians 4:6-7",
    text: "Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God. And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
  },
  {
    chapter: 5,
    verse: 7,
    reference: "1 Peter 5:7",
    text: "Cast all your anxiety on him because he cares for you.",
  },
  {
    chapter: 35,
    verse: 4,
    reference: "Isaiah 35:4",
    text: "Say to those with fearful hearts, 'Be strong, do not fear; your God will come, he will come with vengeance; with divine retribution he will come to save you.'",
  },
  {
    chapter: 23,
    verse: 4,
    reference: "Psalm 23:4",
    text: "Even though I walk through the darkest valley, I will fear no evil, for you are with me; your rod and your staff, they comfort me.",
  },
  {
    chapter: 6,
    verse: 25,
    reference: "Matthew 6:25-27",
    text: "Therefore I tell you, do not worry about your life, what you will eat or drink; or about your body, what you will wear. Is not life more than food, and the body more than clothes? Look at the birds of the air; they do not sow or reap or store away in barns, and yet your heavenly Father feeds them. Are you not much more valuable than they? Can any one of you by worrying add a single hour to your life?",
  },
  {
    chapter: 10,
    verse: 41,
    reference: "Luke 10:41-42",
    text: "Martha, Martha, the Lord answered, you are worried and upset about many things, but few things are needed—or indeed only one. Mary has chosen what is better, and it will not be taken away from her.",
  },
  {
    chapter: 12,
    verse: 22,
    reference: "Luke 12:22-26",
    text: "Then Jesus said to his disciples: 'Therefore I tell you, do not worry about your life, what you will eat; or about your body, what you will wear. For life is more than food, and the body more than clothes. Consider the ravens: They do not sow or reap, they have no storeroom or barn; yet God feeds them. And how much more valuable you are than birds! Who of you by worrying can add a single hour to your life? Since you cannot do this very little thing, why do you worry about the rest?'",
  },
  {
    chapter: 26,
    verse: 3,
    reference: "Isaiah 26:3",
    text: "You will keep in perfect peace those whose minds are steadfast, because they trust in you.",
  },
  {
    chapter: 94,
    verse: 19,
    reference: "Psalm 94:19",
    text: "When anxiety was great within me, your consolation brought me joy.",
  },
  {
    chapter: 55,
    verse: 22,
    reference: "Psalm 55:22",
    text: "Cast your cares on the Lord and he will sustain you; he will never let the righteous be shaken.",
  },
  {
    chapter: 8,
    verse: 6,
    reference: "Romans 8:6",
    text: "The mind governed by the flesh is death, but the mind governed by the Spirit is life and peace.",
  },
  {
    chapter: 1,
    verse: 7,
    reference: "2 Timothy 1:7",
    text: "For the Spirit God gave us does not make us timid, but gives us power, love and self-discipline.",
  },
  {
    chapter: 3,
    verse: 17,
    reference: "Zephaniah 3:17",
    text: "The Lord your God is with you, the Mighty Warrior who saves. He will take great delight in you; in his love he will no longer rebuke you, but will rejoice over you with singing.",
  },
  {
    chapter: 6,
    verse: 34,
    reference: "Matthew 6:34",
    text: "Therefore do not worry about tomorrow, for tomorrow will worry about itself. Each day has enough trouble of its own.",
  },
  {
    chapter: 14,
    verse: 27,
    reference: "John 14:27",
    text: "Peace I leave with you; my peace I give you. I do not give to you as the world gives. Do not let your hearts be troubled and do not be afraid.",
  },
  {
    chapter: 9,
    verse: 6,
    reference: "Isaiah 9:6",
    text: "For to us a child is born, to us a son is given, and the government will be on his shoulders. And he will be called Wonderful Counselor, Mighty God, Everlasting Father, Prince of Peace.",
  },
  {
    chapter: 3,
    verse: 16,
    reference: "Colossians 3:16",
    text: "Let the message of Christ dwell among you richly as you teach and admonish one another with all wisdom through psalms, hymns, and songs from the Spirit, singing to God with gratitude in your hearts.",
  },
  {
    chapter: 9,
    verse: 10,
    reference: "Proverbs 9:10",
    text: "The fear of the Lord is the beginning of wisdom, and knowledge of the Holy One is understanding.",
  },
  {
    chapter: 12,
    verse: 25,
    reference: "Proverbs 12:25",
    text: "Anxiety weighs down the heart, but a kind word cheers it up.",
  },
  {
    chapter: 46,
    verse: 1,
    reference: "Psalm 46:1",
    text: "God is our refuge and strength, an ever-present help in trouble.",
  },
  {
    chapter: 34,
    verse: 4,
    reference: "Psalm 34:4",
    text: "I sought the Lord, and he answered me; he delivered me from all my fears.",
  },
  {
    chapter: 28,
    verse: 20,
    reference: "Matthew 28:20",
    text: "And surely I am with you always, to the very end of the age.",
  },
  {
    chapter: 4,
    verse: 13,
    reference: "Philippians 4:13",
    text: "I can do all this through him who gives me strength.",
  },
];
