import React from "react";
import { useNavigate } from "react-router-dom";
import { BlogPost } from "../../types/types";
import { extractExcerpt } from "../../utils/textUtils";

interface BlogCardProps {
  post: BlogPost;
  index: number;
}

const BlogCard: React.FC<BlogCardProps> = ({ post, index }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/article/${index}`, { state: { post } });
  };

  return (
    <div
      className="block border rounded-lg overflow-hidden hover:scale-105 transform transition duration-300 ease-in-out cursor-pointer"
      onClick={handleCardClick}
    >
      <div
        className="relative"
        style={{ paddingTop: "20.25%", paddingBottom: "40%" }}
      >
        {" "}
        {/* 16:9 Aspect Ratio */}
        <img
          src={post.thumbnail}
          alt={post.title}
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
      </div>
      <div
        className="p-4 md:h-[330px] lg:h-[240px]"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.50)" }}
      >
        <div className="p-4">
          <h3 className="text-lg font-semibold text-white mb-2">
            {post.title}
          </h3>
          <p className="text-sm text-gray-400 mb-2">{post.pubDate}</p>
          <p className="text-lg text-gray-300">
            {extractExcerpt(post.content)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
