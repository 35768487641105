export const PatienceVerses = [
  {
    chapter: 5,
    verse: 7,
    reference: "James 5:7-8",
    text: "Be patient, then, brothers and sisters, until the Lord’s coming. See how the farmer waits for the land to yield its valuable crop, patiently waiting for the autumn and spring rains. You too, be patient and stand firm, because the Lord’s coming is near.",
  },
  {
    chapter: 3,
    verse: 9,
    reference: "2 Peter 3:9",
    text: "The Lord is not slow in keeping his promise, as some understand slowness. Instead he is patient with you, not wanting anyone to perish, but everyone to come to repentance.",
  },
  {
    chapter: 14,
    verse: 29,
    reference: "Proverbs 14:29",
    text: "Whoever is patient has great understanding, but one who is quick-tempered displays folly.",
  },
  {
    chapter: 19,
    verse: 11,
    reference: "Proverbs 19:11",
    text: "A person’s wisdom yields patience; it is to one’s glory to overlook an offense.",
  },
  {
    chapter: 21,
    verse: 5,
    reference: "Proverbs 21:5",
    text: "The plans of the diligent lead to profit as surely as haste leads to poverty.",
  },
  {
    chapter: 15,
    verse: 18,
    reference: "Proverbs 15:18",
    text: "A hot-tempered person stirs up conflict, but the one who is patient calms a quarrel.",
  },
  {
    chapter: 9,
    verse: 22,
    reference: "Galatians 5:22-23",
    text: "But the fruit of the Spirit is love, joy, peace, forbearance, kindness, goodness, faithfulness, gentleness and self-control. Against such things there is no law.",
  },
  {
    chapter: 12,
    verse: 12,
    reference: "Romans 12:12",
    text: "Be joyful in hope, patient in affliction, faithful in prayer.",
  },
  {
    chapter: 4,
    verse: 2,
    reference: "Ephesians 4:2",
    text: "Be completely humble and gentle; be patient, bearing with one another in love.",
  },
  {
    chapter: 3,
    verse: 13,
    reference: "Colossians 3:13",
    text: "Bear with each other and forgive one another if any of you has a grievance against someone. Forgive as the Lord forgave you.",
  },
  {
    chapter: 1,
    verse: 9,
    reference: "James 1:19",
    text: "My dear brothers and sisters, take note of this: Everyone should be quick to listen, slow to speak and slow to become angry.",
  },
  {
    chapter: 6,
    verse: 9,
    reference: "Galatians 6:9",
    text: "Let us not become weary in doing good, for at the proper time we will reap a harvest if we do not give up.",
  },
  {
    chapter: 40,
    verse: 31,
    reference: "Isaiah 40:31",
    text: "But those who hope in the Lord will renew their strength. They will soar on wings like eagles; they will run and not grow weary, they will walk and not be faint.",
  },
  {
    chapter: 3,
    verse: 26,
    reference: "Lamentations 3:26",
    text: "It is good to wait quietly for the salvation of the Lord.",
  },
  {
    chapter: 12,
    verse: 24,
    reference: "Proverbs 12:24",
    text: "Diligent hands will rule, but laziness ends in forced labor.",
  },
  {
    chapter: 29,
    verse: 11,
    reference: "Proverbs 29:11",
    text: "Fools give full vent to their rage, but the wise bring calm in the end.",
  },
  {
    chapter: 13,
    verse: 4,
    reference: "Proverbs 13:4",
    text: "A sluggard’s appetite is never filled, but the desires of the diligent are fully satisfied.",
  },
  {
    chapter: 8,
    verse: 24,
    reference: "Romans 8:24-25",
    text: "For in this hope we were saved. But hope that is seen is no hope at all. Who hopes for what they already have? But if we hope for what we do not yet have, we wait for it patiently.",
  },
  {
    chapter: 37,
    verse: 7,
    reference: "Psalm 37:7",
    text: "Be still before the Lord and wait patiently for him; do not fret when people succeed in their ways, when they carry out their wicked schemes.",
  },
  {
    chapter: 10,
    verse: 12,
    reference: "Hebrews 10:36",
    text: "You need to persevere so that when you have done the will of God, you will receive what he has promised.",
  },
  {
    chapter: 1,
    verse: 3,
    reference: "James 1:3-4",
    text: "Because you know that the testing of your faith produces perseverance. Let perseverance finish its work so that you may be mature and complete, not lacking anything.",
  },
  {
    chapter: 5,
    verse: 10,
    reference: "James 5:10",
    text: "Brothers and sisters, as an example of patience in the face of suffering, take the prophets who spoke in the name of the Lord.",
  },
  {
    chapter: 24,
    verse: 16,
    reference: "Proverbs 24:16",
    text: "For though the righteous fall seven times, they rise again, but the wicked stumble when calamity strikes.",
  },
  {
    chapter: 8,
    verse: 5,
    reference: "Ecclesiastes 8:5-6",
    text: "Whoever obeys his command will come to no harm, and the wise heart will know the proper time and procedure. For there is a proper time and procedure for every matter, though a person may be weighed down by misery.",
  },
];
