// eslint-disable-next-line no-unused-vars
import { BlogPost } from "../types/types";
import { formatDate } from "../utils/dateUtils";
import { extractFirstImage } from "../utils/imageUtils";

const MEDIUM_FEED_URL = "https://medium.com/feed/@alanjonesburga";
const RSS_API_URL = `https://api.rss2json.com/v1/api.json?rss_url=${MEDIUM_FEED_URL}`;

export const fetchBlogPosts = async (): Promise<BlogPost[]> => {
  try {
    const data = await fetchRSSFeed();
    return transformBlogPosts(data.items);
  } catch (error) {
    console.error("Error fetching blog posts:", error);
    throw new Error("Failed to fetch blog posts.");
  }
};

const fetchRSSFeed = async (): Promise<any> => {
  const response = await fetch(RSS_API_URL);
  const data = await response.json();

  if (data.status !== "ok") {
    throw new Error("RSS feed API returned a non-ok status.");
  }
  return data;
};

const transformBlogPosts = (items: any[]): BlogPost[] => {
  return items.slice(0, 4).map((item) => ({
    title: item.title || "Untitled Post",
    link: item.link || "#",
    pubDate: formatDate(item.pubDate),
    content: item.content || "",
    thumbnail: item.thumbnail || extractFirstImage(item.content),
  }));
};
