import React from "react";
import { FaLinkedin, FaGithub, FaEnvelope, FaGlobe } from "react-icons/fa";

export default function FuturePlans() {
  return (
    <div className="relative bg-black bg-opacity-30 p-6 shadow-lg rounded-lg max-w-md">
      {/* Future Plans Section */}
      <div className="relative z-10">
        <div className="text-sm font-bold mb-2 uppercase">
          Coming Soon:{" "}
          <p className="text-blue-400 text-sm uppercase">
            Living For Jesus Christ Dashboard App
          </p>
        </div>
        <p className="text-left text-sm text-white mb-4">
          Stay tuned for the upcoming launch of the Living for Jesus Christ
          Dashboard accompanying application which we are currently developing!{" "}
        </p>
      </div>

      {/* Interested in Hiring Section */}
      <div className="relative z-10 mt-6">
        <h4 className="text-lg font-bold text-yellow-400 mb-2">
          Software Engineering / Web Development Services:
        </h4>
        <ul className="text-left text-white text-sm space-y-3">
          <li className="flex items-center space-x-2">
            <FaGlobe className="text-blue-400" />
            <a
              href="https://codejones.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              Portfolio
            </a>
          </li>
          <li className="flex items-center space-x-2">
            <FaLinkedin className="text-blue-600" />
            <a
              href="https://www.linkedin.com/in/alanjones83/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              LinkedIn
            </a>
          </li>
          <li className="flex items-center space-x-2">
            <FaGithub className="text-gray-300" />
            <a
              href="https://github.com/ajonesb"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              GitHub
            </a>
          </li>
          <li className="flex items-center space-x-2">
            <FaEnvelope className="text-red-400" />
            <a
              href="mailto: alan.jones170183@gmail.com"
              className="hover:text-blue-400 transition"
            >
              alan.jones170183@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
