export const cleanText = (text: string): string =>
  text
    .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
    .replace(/¶/g, "") // Remove paragraph markers
    .replace(/\s+/g, " ") // Normalize whitespace
    .trim();

export const extractChapterNumber = (content: string): number => {
  const match = content.match(/data-sid="[^"]+\.(\d+):/);
  return match ? parseInt(match[1]) : 1; // Default to 1 if no chapter number is found
};

/**
 * Extract an excerpt from a given content string.
 * @param content The full content string.
 * @param maxLength The maximum length of the excerpt (default: 150).
 * @returns A truncated excerpt ending with '...' if content exceeds maxLength.
 */
export const extractExcerpt = (
  content: string,
  maxLength: number = 150
): string => {
  // Strip HTML tags
  const strippedContent = content.replace(/<[^>]+>/g, "");

  // Truncate the content
  return strippedContent.length > maxLength
    ? strippedContent.substring(0, maxLength) + "..."
    : strippedContent;
};
