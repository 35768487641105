import { useCallback } from "react";

export interface SectionRefs {
  homeIntroRef: React.RefObject<HTMLDivElement>;
  bibleVersesRef: React.RefObject<HTMLDivElement>;
  blogListRef: React.RefObject<HTMLDivElement>;
  bibleSearchRef: React.RefObject<HTMLDivElement>;
  flipBooksRef: React.RefObject<HTMLDivElement>;
  bibleAppRef: React.RefObject<HTMLDivElement>;
  mediaRef: React.RefObject<HTMLDivElement>;
  footerRef: React.RefObject<HTMLDivElement>;
}

export const useScrollToSection = ({
  homeIntroRef,
  bibleVersesRef,
  blogListRef,
  bibleSearchRef,
  flipBooksRef,
  bibleAppRef,
  footerRef,
  mediaRef,
}: SectionRefs) => {
  const offsets = {
    homeIntro: { mobile: 100, desktop: 150 },
    bibleVerses: { mobile: 80, desktop: 113 },
    blogList: { mobile: 100, desktop: 110 },
    bibleSearch: { mobile: 70, desktop: 23 },
    flipBooks: { mobile: 70, desktop: 90 },
    bibleApp: { mobile: 70, desktop: 100 },
    media: { mobile: 70, desktop: 90 },
    footer: { mobile: 50, desktop: 70 },
  };

  const scrollWithRetry = (targetPosition: number, attempts = 0) => {
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });

    setTimeout(() => {
      const currentPosition = window.pageYOffset;
      if (Math.abs(currentPosition - targetPosition) > 5 && attempts < 3) {
        scrollWithRetry(targetPosition, attempts + 1);
      }
    }, 100);
  };

  return useCallback(
    (section: string | null, additionalOffset = 0) => {
      if (typeof window === "undefined") return;

      const sectionMapping: Record<string, React.RefObject<HTMLDivElement>> = {
        homeIntro: homeIntroRef,
        bibleVerses: bibleVersesRef,
        blogList: blogListRef,
        bibleSearch: bibleSearchRef,
        flipBooks: flipBooksRef,
        bibleApp: bibleAppRef,
        media: mediaRef,
        footer: footerRef,
      };

      const ref = section ? sectionMapping[section] : null;
      const sectionKey = section ? (section as keyof typeof offsets) : null;

      if (!ref?.current || !sectionKey || !offsets[sectionKey]) {
        console.error("Invalid ref or sectionKey");
        return;
      }

      setTimeout(() => {
        const isMobile = window.innerWidth <= 768;
        const element = ref.current;

        if (!element) {
          console.error("Element is null");
          return;
        }

        const elementPosition = element.getBoundingClientRect().top;
        const headerOffset = isMobile
          ? offsets[sectionKey].mobile
          : offsets[sectionKey].desktop;

        const offsetPosition =
          elementPosition +
          window.pageYOffset -
          headerOffset +
          additionalOffset;

        scrollWithRetry(offsetPosition);
      }, 0);
    },
    [
      homeIntroRef,
      bibleVersesRef,
      blogListRef,
      bibleSearchRef,
      flipBooksRef,
      bibleAppRef,
      mediaRef,
      footerRef,
    ]
  );
};
