export const HopeVerses = [
  {
    chapter: 4,
    verse: 13,
    reference: "Romans 4:13",
    text: "It was not through the law that Abraham and his offspring received the promise that he would be heir of the world, but through the righteousness that comes by faith.",
  },
  {
    chapter: 8,
    verse: 24,
    reference: "Romans 8:24",
    text: "For in this hope we were saved. But hope that is seen is no hope at all. Who hopes for what they already have?",
  },
  {
    chapter: 29,
    verse: 11,
    reference: "Jeremiah 29:11",
    text: "For I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to give you hope and a future.",
  },
  {
    chapter: 12,
    verse: 12,
    reference: "Romans 12:12",
    text: "Be joyful in hope, patient in affliction, faithful in prayer.",
  },
  {
    chapter: 3,
    verse: 22,
    reference: "Lamentations 3:22-23",
    text: "Because of the Lord’s great love we are not consumed, for his compassions never fail. They are new every morning; great is your faithfulness.",
  },
  {
    chapter: 5,
    verse: 5,
    reference: "Romans 5:5",
    text: "And hope does not put us to shame, because God’s love has been poured out into our hearts through the Holy Spirit, who has been given to us.",
  },
  {
    chapter: 31,
    verse: 8,
    reference: "Deuteronomy 31:8",
    text: "The Lord himself goes before you and will be with you; he will never leave you nor forsake you. Do not be afraid; do not be discouraged.",
  },
  {
    chapter: 6,
    verse: 19,
    reference: "Hebrews 6:19",
    text: "We have this hope as an anchor for the soul, firm and secure. It enters the inner sanctuary behind the curtain.",
  },
  {
    chapter: 23,
    verse: 4,
    reference: "Psalm 23:4",
    text: "Even though I walk through the darkest valley, I will fear no evil, for you are with me; your rod and your staff, they comfort me.",
  },
  {
    chapter: 3,
    verse: 21,
    reference: "Lamentations 3:21-23",
    text: "Yet this I call to mind and therefore I have hope: Because of the Lord’s great love we are not consumed, for his compassions never fail. They are new every morning; great is your faithfulness.",
  },
  {
    chapter: 33,
    verse: 22,
    reference: "Psalm 33:22",
    text: "May your unfailing love be with us, Lord, even as we put our hope in you.",
  },
  {
    chapter: 42,
    verse: 11,
    reference: "Psalm 42:11",
    text: "Why, my soul, are you downcast? Why so disturbed within me? Put your hope in God, for I will yet praise him, my Savior and my God.",
  },
  {
    chapter: 11,
    verse: 1,
    reference: "Hebrews 11:1",
    text: "Now faith is confidence in what we hope for and assurance about what we do not see.",
  },
  {
    chapter: 15,
    verse: 13,
    reference: "Romans 15:13",
    text: "May the God of hope fill you with all joy and peace as you trust in him, so that you may overflow with hope by the power of the Holy Spirit.",
  },
  {
    chapter: 37,
    verse: 9,
    reference: "Psalm 37:9",
    text: "For those who are evil will be destroyed, but those who hope in the Lord will inherit the land.",
  },
  {
    chapter: 3,
    verse: 17,
    reference: "Zephaniah 3:17",
    text: "The Lord your God is with you, the Mighty Warrior who saves. He will take great delight in you; in his love he will no longer rebuke you, but will rejoice over you with singing.",
  },
  {
    chapter: 10,
    verse: 24,
    reference: "Hebrews 10:24-25",
    text: "And let us consider how we may spur one another on toward love and good deeds, not giving up meeting together, as some are in the habit of doing, but encouraging one another—and all the more as you see the Day approaching.",
  },
  {
    chapter: 13,
    verse: 18,
    reference: "Proverbs 13:12",
    text: "Hope deferred makes the heart sick, but a longing fulfilled is a tree of life.",
  },
  {
    chapter: 5,
    verse: 3,
    reference: "Matthew 5:3-4",
    text: "Blessed are the poor in spirit, for theirs is the kingdom of heaven. Blessed are those who mourn, for they will be comforted.",
  },
  {
    chapter: 1,
    verse: 3,
    reference: "1 Peter 1:3",
    text: "Praise be to the God and Father of our Lord Jesus Christ! In his great mercy he has given us new birth into a living hope through the resurrection of Jesus Christ from the dead.",
  },
  {
    chapter: 10,
    verse: 23,
    reference: "Hebrews 10:23",
    text: "Let us hold unswervingly to the hope we profess, for he who promised is faithful.",
  },
  {
    chapter: 30,
    verse: 5,
    reference: "Psalm 30:5",
    text: "For his anger lasts only a moment, but his favor lasts a lifetime; weeping may stay for the night, but rejoicing comes in the morning.",
  },
  {
    chapter: 9,
    verse: 11,
    reference: "Jeremiah 17:7",
    text: "But blessed is the one who trusts in the Lord, whose confidence is in him.",
  },
  {
    chapter: 25,
    verse: 5,
    reference: "Psalm 25:5",
    text: "Guide me in your truth and teach me, for you are God my Savior, and my hope is in you all day long.",
  },
  {
    chapter: 5,
    verse: 4,
    reference: "1 Thessalonians 5:8",
    text: "But since we belong to the day, let us be sober, putting on faith and love as a breastplate, and the hope of salvation as a helmet.",
  },
];
