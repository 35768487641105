export const WisdomVerses = [
  {
    chapter: 2,
    verse: 6,
    reference: "James 1:5",
    text: "If any of you lacks wisdom, you should ask God, who gives generously to all without finding fault, and it will be given to you.",
  },
  {
    chapter: 3,
    verse: 13,
    reference: "James 3:13",
    text: "Who is wise and understanding among you? Let them show it by their good life, by deeds done in the humility that comes from wisdom.",
  },
  {
    chapter: 9,
    verse: 10,
    reference: "Proverbs 9:10",
    text: "The fear of the Lord is the beginning of wisdom, and knowledge of the Holy One is understanding.",
  },
  {
    chapter: 3,
    verse: 7,
    reference: "Proverbs 3:7",
    text: "Do not be wise in your own eyes; fear the Lord and shun evil.",
  },
  {
    chapter: 8,
    verse: 11,
    reference: "Proverbs 8:11",
    text: "For wisdom is more precious than rubies, and nothing you desire can compare with her.",
  },
  {
    chapter: 2,
    verse: 10,
    reference: "Proverbs 2:10",
    text: "For wisdom will enter your heart, and knowledge will be pleasant to your soul.",
  },
  {
    chapter: 4,
    verse: 7,
    reference: "Proverbs 4:7",
    text: "The beginning of wisdom is this: Get wisdom. Though it cost all you have, get understanding.",
  },
  {
    chapter: 19,
    verse: 20,
    reference: "Proverbs 19:20",
    text: "Listen to advice and accept discipline, and at the end you will be counted among the wise.",
  },
  {
    chapter: 28,
    verse: 26,
    reference: "Proverbs 28:26",
    text: "Those who trust in themselves are fools, but those who walk in wisdom are kept safe.",
  },
  {
    chapter: 11,
    verse: 2,
    reference: "Proverbs 11:2",
    text: "When pride comes, then comes disgrace, but with humility comes wisdom.",
  },
  {
    chapter: 3,
    verse: 19,
    reference: "Proverbs 3:19",
    text: "By wisdom the Lord laid the earth’s foundations, by understanding he set the heavens in place.",
  },
  {
    chapter: 16,
    verse: 16,
    reference: "Proverbs 16:16",
    text: "How much better to get wisdom than gold, to get insight rather than silver!",
  },
  {
    chapter: 7,
    verse: 24,
    reference: "Matthew 7:24",
    text: "Therefore everyone who hears these words of mine and puts them into practice is like a wise man who built his house on the rock.",
  },
  {
    chapter: 28,
    verse: 18,
    reference: "Job 28:18",
    text: "Coral and jasper are not worthy of mention; the price of wisdom is beyond rubies.",
  },
  {
    chapter: 2,
    verse: 6,
    reference: "Proverbs 2:6",
    text: "For the Lord gives wisdom; from his mouth come knowledge and understanding.",
  },
  {
    chapter: 1,
    verse: 7,
    reference: "Proverbs 1:7",
    text: "The fear of the Lord is the beginning of knowledge, but fools despise wisdom and instruction.",
  },
  {
    chapter: 37,
    verse: 24,
    reference: "Job 37:24",
    text: "Therefore, people revere him, for does he not have regard for all the wise in heart?",
  },
  {
    chapter: 3,
    verse: 13,
    reference: "Proverbs 3:13",
    text: "Blessed are those who find wisdom, those who gain understanding.",
  },
  {
    chapter: 2,
    verse: 12,
    reference: "Ecclesiastes 2:13",
    text: "I saw that wisdom is better than folly, just as light is better than darkness.",
  },
  {
    chapter: 24,
    verse: 14,
    reference: "Proverbs 24:14",
    text: "Know also that wisdom is like honey for you: If you find it, there is a future hope for you, and your hope will not be cut off.",
  },
  {
    chapter: 10,
    verse: 13,
    reference: "Proverbs 10:13",
    text: "Wisdom is found on the lips of the discerning, but a rod is for the back of one who has no sense.",
  },
  {
    chapter: 3,
    verse: 15,
    reference: "Proverbs 3:15",
    text: "She is more precious than rubies; nothing you desire can compare with her.",
  },
  {
    chapter: 4,
    verse: 13,
    reference: "Proverbs 4:13",
    text: "Hold on to instruction, do not let it go; guard it well, for it is your life.",
  },
  {
    chapter: 1,
    verse: 22,
    reference: "Colossians 1:22",
    text: "But now he has reconciled you by Christ’s physical body through death to present you holy in his sight, without blemish and free from accusation.",
  },
  {
    chapter: 1,
    verse: 17,
    reference: "Colossians 1:17",
    text: "He is before all things, and in him all things hold together.",
  },
];
