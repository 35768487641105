import React from "react";
import IframeViewer from "./IFrameViewer";

const FetchBible: React.FC = () => {
  return (
    <div className="p-6 bg-black bg-opacity-60 shadow-lg mx-auto border-gray-500 border-y drop-shadow-lg">
      <h2 className="text-2xl md:text-3xl text-white font-bold mb-4 text-center uppercase mt-5">
        Bible App
      </h2>
      <p className="text-white text-lg text-center p-1 mb-4">
        Here's a really nice Bible App provided by{" "}
        <a
          href="https://gracious.tech/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 underline hover:text-blue-600"
        >
          Gracious Tech
        </a>{" "}
        which has other bible tech resources to share the Gospel. I highly
        recommend you check them out.
      </p>

      <IframeViewer
        url="https://app.fetch.bible"
        onClose={() => {}}
        hideCloseButton={true}
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          border: "none",
        }}
      />
    </div>
  );
};

export default FetchBible;
