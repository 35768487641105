export const ComfortVerses = [
  {
    chapter: 1,
    verse: 3,
    reference: "2 Corinthians 1:3-4",
    text: "Praise be to the God and Father of our Lord Jesus Christ, the Father of compassion and the God of all comfort, who comforts us in all our troubles, so that we can comfort those in any trouble with the comfort we ourselves receive from God.",
  },
  {
    chapter: 4,
    verse: 16,
    reference: "2 Corinthians 4:16-18",
    text: "Therefore we do not lose heart. Though outwardly we are wasting away, yet inwardly we are being renewed day by day. For our light and momentary troubles are achieving for us an eternal glory that far outweighs them all. So we fix our eyes not on what is seen, but on what is unseen, since what is seen is temporary, but what is unseen is eternal.",
  },
  {
    chapter: 14,
    verse: 1,
    reference: "John 14:1",
    text: "Do not let your hearts be troubled. You believe in God; believe also in me.",
  },
  {
    chapter: 23,
    verse: 4,
    reference: "Psalm 23:4",
    text: "Even though I walk through the darkest valley, I will fear no evil, for you are with me; your rod and your staff, they comfort me.",
  },
  {
    chapter: 34,
    verse: 18,
    reference: "Psalm 34:18",
    text: "The Lord is close to the brokenhearted and saves those who are crushed in spirit.",
  },
  {
    chapter: 46,
    verse: 1,
    reference: "Psalm 46:1",
    text: "God is our refuge and strength, an ever-present help in trouble.",
  },
  {
    chapter: 9,
    verse: 10,
    reference: "Isaiah 41:10",
    text: "So do not fear, for I am with you; do not be dismayed, for I am your God. I will strengthen you and help you; I will uphold you with my righteous right hand.",
  },
  {
    chapter: 3,
    verse: 22,
    reference: "Lamentations 3:22-23",
    text: "Because of the Lord’s great love we are not consumed, for his compassions never fail. They are new every morning; great is your faithfulness.",
  },
  {
    chapter: 43,
    verse: 2,
    reference: "Isaiah 43:2",
    text: "When you pass through the waters, I will be with you; and when you pass through the rivers, they will not sweep over you. When you walk through the fire, you will not be burned; the flames will not set you ablaze.",
  },
  {
    chapter: 4,
    verse: 13,
    reference: "Philippians 4:13",
    text: "I can do all this through him who gives me strength.",
  },
  {
    chapter: 8,
    verse: 28,
    reference: "Romans 8:28",
    text: "And we know that in all things God works for the good of those who love him, who have been called according to his purpose.",
  },
  {
    chapter: 31,
    verse: 8,
    reference: "Deuteronomy 31:8",
    text: "The Lord himself goes before you and will be with you; he will never leave you nor forsake you. Do not be afraid; do not be discouraged.",
  },
  {
    chapter: 28,
    verse: 20,
    reference: "Matthew 28:20",
    text: "And surely I am with you always, to the very end of the age.",
  },
  {
    chapter: 3,
    verse: 16,
    reference: "John 3:16",
    text: "For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life.",
  },
  {
    chapter: 12,
    verse: 49,
    reference: "Luke 12:49-50",
    text: "I have come to bring fire on the earth, and how I wish it were already kindled!",
  },
  {
    chapter: 11,
    verse: 28,
    reference: "Matthew 11:28-30",
    text: "Come to me, all you who are weary and burdened, and I will give you rest. Take my yoke upon you and learn from me, for I am gentle and humble in heart, and you will find rest for your souls. For my yoke is easy and my burden is light.",
  },
  {
    chapter: 27,
    verse: 1,
    reference: "Psalm 27:1",
    text: "The Lord is my light and my salvation—whom shall I fear? The Lord is the stronghold of my life—of whom shall I be afraid?",
  },
  {
    chapter: 9,
    verse: 6,
    reference: "Isaiah 9:6",
    text: "For to us a child is born, to us a son is given, and the government will be on his shoulders. And he will be called Wonderful Counselor, Mighty God, Everlasting Father, Prince of Peace.",
  },
  {
    chapter: 4,
    verse: 7,
    reference: "Philippians 4:7",
    text: "And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
  },
  {
    chapter: 21,
    verse: 4,
    reference: "Revelation 21:4",
    text: "He will wipe every tear from their eyes. There will be no more death or mourning or crying or pain, for the old order of things has passed away.",
  },
  {
    chapter: 19,
    verse: 1,
    reference: "Psalm 94:19",
    text: "When anxiety was great within me, your consolation brought me joy.",
  },
  {
    chapter: 7,
    verse: 38,
    reference: "John 7:38",
    text: "Whoever believes in me, as Scripture has said, rivers of living water will flow from within them.",
  },
  {
    chapter: 7,
    verse: 14,
    reference: "2 Chronicles 7:14",
    text: "If my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then I will hear from heaven, and I will forgive their sin and will heal their land.",
  },
];
