import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer/Footer";

const ArticleView: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { post } = location.state || {};

  if (!post) {
    navigate("/");
    return null;
  }

  const sanitizeContent = (
    content: string,
    title: string,
    thumbnail: string
  ) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    const figures = doc.querySelectorAll("figure img");
    figures.forEach((img) => {
      if ((img as HTMLImageElement).src === thumbnail) {
        img.parentElement?.remove();
      }
    });

    const headings = doc.querySelectorAll("h1, h2, h3");
    headings.forEach((heading) => {
      if (heading.textContent?.trim() === title.trim()) {
        heading.remove();
      }
    });

    const links = doc.querySelectorAll("a");
    links.forEach((link) => {
      const href = link.getAttribute("href");
      if (href && href.includes("youtube.com/watch?v=")) {
        const videoId = href.split("v=")[1]?.split("&")[0];
        if (videoId) {
          const iframe = document.createElement("iframe");
          iframe.src = `https://www.youtube.com/embed/${videoId}`;
          iframe.width = "100%";
          iframe.height = "360";
          iframe.allow =
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
          iframe.allowFullscreen = true;
          link.replaceWith(iframe);
        }
      } else if (href) {
        const anchor = document.createElement("a");
        anchor.href = href;
        anchor.target = "_blank";
        anchor.rel = "noopener noreferrer";
        anchor.textContent = href;
        anchor.style.wordWrap = "break-word";
        link.replaceWith(anchor);
      }
    });

    return doc.body.innerHTML;
  };

  const sanitizedContent = sanitizeContent(
    post.content,
    post.title,
    post.thumbnail
  );

  const handleNavigateToSection = (section: string) => {
    navigate("/", { state: { section } });
  };

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100">
        <div className="relative h-96">
          <img
            src={post.thumbnail}
            alt={post.title}
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <h1 className="text-4xl font-bold text-white px-4 text-center">
              {post.title}
            </h1>
          </div>
        </div>
        <div className="container mx-auto py-10 px-4 lg:px-32">
          <p className="text-sm text-gray-500 text-left mb-6">{post.pubDate}</p>
          <div
            className="prose prose-lg max-w-none mx-auto bg-white p-8 shadow-lg rounded-lg"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
          <div className="mt-10 flex justify-between">
            <button
              className="text-blue-500 hover:underline bg-transparent border border-blue-500 rounded py-2 px-4"
              onClick={() => handleNavigateToSection("homeIntro")}
            >
              ← Back to Home
            </button>
            <button
              className="text-blue-500 hover:underline bg-transparent border border-blue-500 rounded py-2 px-4"
              onClick={() => handleNavigateToSection("blogList")}
            >
              ← Back to Articles
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleView;
