export const HealingVerses = [
  {
    chapter: 5,
    verse: 16,
    reference: "James 5:16",
    text: "Therefore confess your sins to each other and pray for each other so that you may be healed. The prayer of a righteous person is powerful and effective.",
  },
  {
    chapter: 10,
    verse: 9,
    reference: "Matthew 10:9",
    text: "Heal the sick, raise the dead, cleanse those who have leprosy, drive out demons. Freely you have received; freely give.",
  },
  {
    chapter: 15,
    verse: 26,
    reference: "Exodus 15:26",
    text: "He said, 'If you listen carefully to the Lord your God and do what is right in his eyes, if you pay attention to his commands and keep all his decrees, I will not bring on you any of the diseases I brought on the Egyptians, for I am the Lord, who heals you.'",
  },
  {
    chapter: 30,
    verse: 17,
    reference: "Jeremiah 30:17",
    text: "But I will restore you to health and heal your wounds, declares the Lord, because you are called an outcast, Zion for whom no one cares.",
  },
  {
    chapter: 53,
    verse: 5,
    reference: "Isaiah 53:5",
    text: "But he was pierced for our transgressions, he was crushed for our iniquities; the punishment that brought us peace was on him, and by his wounds we are healed.",
  },
  {
    chapter: 3,
    verse: 8,
    reference: "Proverbs 3:8",
    text: "This will bring health to your body and nourishment to your bones.",
  },
  {
    chapter: 6,
    verse: 19,
    reference: "1 Corinthians 6:19-20",
    text: "Do you not know that your bodies are temples of the Holy Spirit, who is in you, whom you have received from God? You are not your own; you were bought at a price. Therefore honor God with your bodies.",
  },
  {
    chapter: 6,
    verse: 14,
    reference: "2 Chronicles 7:14",
    text: "If my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then I will hear from heaven, and I will forgive their sin and will heal their land.",
  },
  {
    chapter: 19,
    verse: 22,
    reference: "Proverbs 19:22",
    text: "The fear of the Lord leads to life; then one rests content, untouched by trouble.",
  },
  {
    chapter: 41,
    verse: 3,
    reference: "Psalm 41:3",
    text: "The Lord sustains them on their sickbed and restores them from their bed of illness.",
  },
  {
    chapter: 4,
    verse: 20,
    reference: "Proverbs 4:20-22",
    text: "My son, pay attention to what I say; turn your ear to my words. Do not let them out of your sight, keep them within your heart; for they are life to those who find them and health to one’s whole body.",
  },
  {
    chapter: 17,
    verse: 22,
    reference: "Proverbs 17:22",
    text: "A cheerful heart is good medicine, but a crushed spirit dries up the bones.",
  },
  {
    chapter: 9,
    verse: 12,
    reference: "Matthew 9:12",
    text: "On hearing this, Jesus said, 'It is not the healthy who need a doctor, but the sick.'",
  },
  {
    chapter: 4,
    verse: 18,
    reference: "Luke 4:18",
    text: "The Spirit of the Lord is on me, because he has anointed me to proclaim good news to the poor. He has sent me to proclaim freedom for the prisoners and recovery of sight for the blind, to set the oppressed free.",
  },
  {
    chapter: 7,
    verse: 50,
    reference: "Luke 7:50",
    text: "Jesus said to the woman, 'Your faith has saved you; go in peace.'",
  },
  {
    chapter: 3,
    verse: 16,
    reference: "1 Corinthians 3:16-17",
    text: "Don’t you know that you yourselves are God’s temple and that God’s Spirit dwells in your midst? If anyone destroys God’s temple, God will destroy that person; for God’s temple is sacred, and you together are that temple.",
  },
  {
    chapter: 6,
    verse: 2,
    reference: "Hosea 6:1-2",
    text: "Come, let us return to the Lord. He has torn us to pieces but he will heal us; he has injured us but he will bind up our wounds. After two days he will revive us; on the third day he will restore us, that we may live in his presence.",
  },
  {
    chapter: 16,
    verse: 24,
    reference: "Proverbs 16:24",
    text: "Gracious words are a honeycomb, sweet to the soul and healing to the bones.",
  },
  {
    chapter: 103,
    verse: 2,
    reference: "Psalm 103:2-3",
    text: "Praise the Lord, my soul, and forget not all his benefits—who forgives all your sins and heals all your diseases.",
  },
  {
    chapter: 1,
    verse: 8,
    reference: "3 John 1:2",
    text: "Dear friend, I pray that you may enjoy good health and that all may go well with you, even as your soul is getting along well.",
  },
  {
    chapter: 30,
    verse: 17,
    reference: "Jeremiah 33:6",
    text: "Nevertheless, I will bring health and healing to it; I will heal my people and will let them enjoy abundant peace and security.",
  },
  {
    chapter: 107,
    verse: 19,
    reference: "Psalm 107:19-20",
    text: "Then they cried to the Lord in their trouble, and he saved them from their distress. He sent out his word and healed them; he rescued them from the grave.",
  },
  {
    chapter: 2,
    verse: 24,
    reference: "1 Peter 2:24",
    text: "He himself bore our sins in his body on the cross, so that we might die to sins and live for righteousness; by his wounds you have been healed.",
  },
  {
    chapter: 34,
    verse: 18,
    reference: "Psalm 34:18",
    text: "The Lord is close to the brokenhearted and saves those who are crushed in spirit.",
  },
];
