export const LoveVerses = [
  {
    chapter: 3,
    verse: 16,
    reference: "1 John 3:16",
    text: "This is how we know what love is: Jesus Christ laid down his life for us. And we ought to lay down our lives for our brothers and sisters.",
  },
  {
    chapter: 4,
    verse: 7,
    reference: "1 John 4:7",
    text: "Dear friends, let us love one another, for love comes from God. Everyone who loves has been born of God and knows God.",
  },
  {
    chapter: 13,
    verse: 34,
    reference: "John 13:34",
    text: "A new command I give you: Love one another. As I have loved you, so you must love one another.",
  },
  {
    chapter: 4,
    verse: 8,
    reference: "1 John 4:8",
    text: "Whoever does not love does not know God, because God is love.",
  },
  {
    chapter: 4,
    verse: 19,
    reference: "1 John 4:19",
    text: "We love because he first loved us.",
  },
  {
    chapter: 13,
    verse: 4,
    reference: "1 Corinthians 13:4-5",
    text: "Love is patient, love is kind. It does not envy, it does not boast, it is not proud. It does not dishonor others, it is not self-seeking, it is not easily angered, it keeps no record of wrongs.",
  },
  {
    chapter: 13,
    verse: 6,
    reference: "1 Corinthians 13:6-7",
    text: "Love does not delight in evil but rejoices with the truth. It always protects, always trusts, always hopes, always perseveres.",
  },
  {
    chapter: 13,
    verse: 13,
    reference: "1 Corinthians 13:13",
    text: "And now these three remain: faith, hope and love. But the greatest of these is love.",
  },
  {
    chapter: 5,
    verse: 1,
    reference: "Ephesians 5:1-2",
    text: "Follow God’s example, therefore, as dearly loved children and walk in the way of love, just as Christ loved us and gave himself up for us as a fragrant offering and sacrifice to God.",
  },
  {
    chapter: 12,
    verse: 9,
    reference: "Romans 12:9",
    text: "Love must be sincere. Hate what is evil; cling to what is good.",
  },
  {
    chapter: 12,
    verse: 10,
    reference: "Romans 12:10",
    text: "Be devoted to one another in love. Honor one another above yourselves.",
  },
  {
    chapter: 5,
    verse: 22,
    reference: "Galatians 5:22-23",
    text: "But the fruit of the Spirit is love, joy, peace, forbearance, kindness, goodness, faithfulness, gentleness and self-control. Against such things there is no law.",
  },
  {
    chapter: 13,
    verse: 5,
    reference: "Hebrews 13:5",
    text: "Keep your lives free from the love of money and be content with what you have, because God has said, 'Never will I leave you; never will I forsake you.'",
  },
  {
    chapter: 15,
    verse: 13,
    reference: "John 15:13",
    text: "Greater love has no one than this: to lay down one’s life for one’s friends.",
  },
  {
    chapter: 3,
    verse: 18,
    reference: "1 John 3:18",
    text: "Dear children, let us not love with words or speech but with actions and in truth.",
  },
  {
    chapter: 14,
    verse: 1,
    reference: "Proverbs 10:12",
    text: "Hatred stirs up conflict, but love covers over all wrongs.",
  },
  {
    chapter: 2,
    verse: 8,
    reference: "1 Peter 4:8",
    text: "Above all, love each other deeply, because love covers over a multitude of sins.",
  },
  {
    chapter: 19,
    verse: 18,
    reference: "Leviticus 19:18",
    text: "Do not seek revenge or bear a grudge against anyone among your people, but love your neighbor as yourself. I am the Lord.",
  },
  {
    chapter: 3,
    verse: 11,
    reference: "1 John 3:11",
    text: "For this is the message you heard from the beginning: We should love one another.",
  },
  {
    chapter: 6,
    verse: 35,
    reference: "Luke 6:35",
    text: "But love your enemies, do good to them, and lend to them without expecting to get anything back. Then your reward will be great, and you will be children of the Most High, because he is kind to the ungrateful and wicked.",
  },
  {
    chapter: 5,
    verse: 44,
    reference: "Matthew 5:44",
    text: "But I tell you, love your enemies and pray for those who persecute you.",
  },
  {
    chapter: 4,
    verse: 12,
    reference: "1 John 4:12",
    text: "No one has ever seen God; but if we love one another, God lives in us and his love is made complete in us.",
  },
  {
    chapter: 5,
    verse: 8,
    reference: "Romans 5:8",
    text: "But God demonstrates his own love for us in this: While we were still sinners, Christ died for us.",
  },
  {
    chapter: 4,
    verse: 19,
    reference: "1 John 4:19",
    text: "We love because he first loved us.",
  },
];
