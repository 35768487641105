export const SalvationVerses = [
  {
    chapter: 2,
    verse: 8,
    reference: "Ephesians 2:8-9",
    text: "For it is by grace you have been saved, through faith—and this is not from yourselves, it is the gift of God—not by works, so that no one can boast.",
  },
  {
    chapter: 1,
    verse: 9,
    reference: "1 Timothy 1:9",
    text: "He has saved us and called us to a holy life—not because of anything we have done but because of his own purpose and grace. This grace was given us in Christ Jesus before the beginning of time.",
  },
  {
    chapter: 3,
    verse: 16,
    reference: "John 3:16",
    text: "For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life.",
  },
  {
    chapter: 6,
    verse: 23,
    reference: "Romans 6:23",
    text: "For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.",
  },
  {
    chapter: 10,
    verse: 9,
    reference: "Romans 10:9",
    text: "If you declare with your mouth, 'Jesus is Lord,' and believe in your heart that God raised him from the dead, you will be saved.",
  },
  {
    chapter: 10,
    verse: 13,
    reference: "Romans 10:13",
    text: "For, 'Everyone who calls on the name of the Lord will be saved.'",
  },
  {
    chapter: 3,
    verse: 21,
    reference: "Titus 3:5",
    text: "He saved us, not because of righteous things we had done, but because of his mercy. He saved us through the washing of rebirth and renewal by the Holy Spirit.",
  },
  {
    chapter: 5,
    verse: 24,
    reference: "John 5:24",
    text: "Very truly I tell you, whoever hears my word and believes him who sent me has eternal life and will not be judged but has crossed over from death to life.",
  },
  {
    chapter: 4,
    verse: 12,
    reference: "Acts 4:12",
    text: "Salvation is found in no one else, for there is no other name under heaven given to mankind by which we must be saved.",
  },
  {
    chapter: 7,
    verse: 14,
    reference: "2 Chronicles 7:14",
    text: "If my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then I will hear from heaven, and I will forgive their sin and will heal their land.",
  },
  {
    chapter: 3,
    verse: 23,
    reference: "Romans 3:23-24",
    text: "For all have sinned and fall short of the glory of God, and all are justified freely by his grace through the redemption that came by Christ Jesus.",
  },
  {
    chapter: 14,
    verse: 6,
    reference: "John 14:6",
    text: "Jesus answered, 'I am the way and the truth and the life. No one comes to the Father except through me.'",
  },
  {
    chapter: 2,
    verse: 11,
    reference: "Titus 2:11",
    text: "For the grace of God has appeared that offers salvation to all people.",
  },
  {
    chapter: 2,
    verse: 4,
    reference: "1 Timothy 2:4",
    text: "Who wants all people to be saved and to come to a knowledge of the truth.",
  },
  {
    chapter: 4,
    verse: 8,
    reference: "Psalm 62:1",
    text: "Truly my soul finds rest in God; my salvation comes from him.",
  },
  {
    chapter: 3,
    verse: 17,
    reference: "John 3:17",
    text: "For God did not send his Son into the world to condemn the world, but to save the world through him.",
  },
  {
    chapter: 1,
    verse: 12,
    reference: "John 1:12",
    text: "Yet to all who did receive him, to those who believed in his name, he gave the right to become children of God.",
  },
  {
    chapter: 9,
    verse: 22,
    reference: "Hebrews 9:22",
    text: "In fact, the law requires that nearly everything be cleansed with blood, and without the shedding of blood there is no forgiveness.",
  },
  {
    chapter: 2,
    verse: 5,
    reference: "Ephesians 2:5",
    text: "Made us alive with Christ even when we were dead in transgressions—it is by grace you have been saved.",
  },
  {
    chapter: 8,
    verse: 1,
    reference: "Romans 8:1",
    text: "Therefore, there is now no condemnation for those who are in Christ Jesus.",
  },
  {
    chapter: 1,
    verse: 13,
    reference: "Ephesians 1:13",
    text: "And you also were included in Christ when you heard the message of truth, the gospel of your salvation. When you believed, you were marked in him with a seal, the promised Holy Spirit.",
  },
  {
    chapter: 5,
    verse: 8,
    reference: "Romans 5:8",
    text: "But God demonstrates his own love for us in this: While we were still sinners, Christ died for us.",
  },
  {
    chapter: 15,
    verse: 21,
    reference: "1 Corinthians 15:21-22",
    text: "For since death came through a man, the resurrection of the dead comes also through a man. For as in Adam all die, so in Christ all will be made alive.",
  },
  {
    chapter: 6,
    verse: 40,
    reference: "John 6:40",
    text: "For my Father’s will is that everyone who looks to the Son and believes in him shall have eternal life, and I will raise them up at the last day.",
  },
];
