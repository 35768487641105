export const FearVerses = [
  {
    chapter: 41,
    verse: 10,
    reference: "Isaiah 41:10",
    text: "So do not fear, for I am with you; do not be dismayed, for I am your God. I will strengthen you and help you; I will uphold you with my righteous right hand.",
  },
  {
    chapter: 34,
    verse: 4,
    reference: "Psalm 34:4",
    text: "I sought the Lord, and he answered me; he delivered me from all my fears.",
  },
  {
    chapter: 43,
    verse: 1,
    reference: "Isaiah 43:1",
    text: "But now, this is what the Lord says—he who created you, Jacob, he who formed you, Israel: 'Do not fear, for I have redeemed you; I have summoned you by name; you are mine.'",
  },
  {
    chapter: 46,
    verse: 1,
    reference: "Psalm 46:1",
    text: "God is our refuge and strength, an ever-present help in trouble.",
  },
  {
    chapter: 1,
    verse: 7,
    reference: "2 Timothy 1:7",
    text: "For the Spirit God gave us does not make us timid, but gives us power, love and self-discipline.",
  },
  {
    chapter: 56,
    verse: 3,
    reference: "Psalm 56:3-4",
    text: "When I am afraid, I put my trust in you. In God, whose word I praise—in God I trust and am not afraid. What can mere mortals do to me?",
  },
  {
    chapter: 3,
    verse: 5,
    reference: "Proverbs 3:5-6",
    text: "Trust in the Lord with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight.",
  },
  {
    chapter: 4,
    verse: 13,
    reference: "Philippians 4:13",
    text: "I can do all this through him who gives me strength.",
  },
  {
    chapter: 27,
    verse: 1,
    reference: "Psalm 27:1",
    text: "The Lord is my light and my salvation—whom shall I fear? The Lord is the stronghold of my life—of whom shall I be afraid?",
  },
  {
    chapter: 35,
    verse: 4,
    reference: "Isaiah 35:4",
    text: "Say to those with fearful hearts, 'Be strong, do not fear; your God will come, he will come with vengeance; with divine retribution he will come to save you.'",
  },
  {
    chapter: 14,
    verse: 1,
    reference: "John 14:1",
    text: "Do not let your hearts be troubled. You believe in God; believe also in me.",
  },
  {
    chapter: 14,
    verse: 27,
    reference: "John 14:27",
    text: "Peace I leave with you; my peace I give you. I do not give to you as the world gives. Do not let your hearts be troubled and do not be afraid.",
  },
  {
    chapter: 4,
    verse: 6,
    reference: "Philippians 4:6-7",
    text: "Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God. And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
  },
  {
    chapter: 31,
    verse: 8,
    reference: "Deuteronomy 31:8",
    text: "The Lord himself goes before you and will be with you; he will never leave you nor forsake you. Do not be afraid; do not be discouraged.",
  },
  {
    chapter: 23,
    verse: 4,
    reference: "Psalm 23:4",
    text: "Even though I walk through the darkest valley, I will fear no evil, for you are with me; your rod and your staff, they comfort me.",
  },
  {
    chapter: 8,
    verse: 15,
    reference: "Romans 8:15",
    text: "The Spirit you received does not make you slaves, so that you live in fear again; rather, the Spirit you received brought about your adoption to sonship. And by him we cry, 'Abba, Father.'",
  },
  {
    chapter: 28,
    verse: 20,
    reference: "Matthew 28:20",
    text: "And surely I am with you always, to the very end of the age.",
  },
  {
    chapter: 31,
    verse: 13,
    reference: "Joshua 1:9",
    text: "Have I not commanded you? Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go.",
  },
  {
    chapter: 9,
    verse: 10,
    reference: "Proverbs 9:10",
    text: "The fear of the Lord is the beginning of wisdom, and knowledge of the Holy One is understanding.",
  },
  {
    chapter: 7,
    verse: 14,
    reference: "2 Chronicles 7:14",
    text: "If my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then I will hear from heaven, and I will forgive their sin and will heal their land.",
  },
  {
    chapter: 94,
    verse: 19,
    reference: "Psalm 94:19",
    text: "When anxiety was great within me, your consolation brought me joy.",
  },
  {
    chapter: 61,
    verse: 3,
    reference: "Isaiah 61:3",
    text: "To provide for those who grieve in Zion—to bestow on them a crown of beauty instead of ashes, the oil of joy instead of mourning, and a garment of praise instead of a spirit of despair.",
  },
  {
    chapter: 3,
    verse: 17,
    reference: "Zephaniah 3:17",
    text: "The Lord your God is with you, the Mighty Warrior who saves. He will take great delight in you; in his love he will no longer rebuke you, but will rejoice over you with singing.",
  },
];
