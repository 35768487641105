export const BibleStudyPdfs = [
  {
    title: "Life Apllication Study Bible Preview - John, Philippians",
    thumbnail: "/thumbnails/lifeappbible.png",
    pdf: "https://www.paperturn-view.com/tyndale-bibles/nlt-life-application-study-bible-third-edition?pid=NTI52992&v=1.34",
    author: "Tyndale House Publishers",
  },
  {
    title: "NLT Study Bible",
    thumbnail: "/thumbnails/nlt-study-bible.png",
    pdf: "https://docs.google.com/viewer?srcid=1VzTPMAZ79jauKXHViC-4-aSDHgfihQ3l&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
    author: "Tyndale House Publishers",
  },
  {
    title: "Atlas of the Bible",
    thumbnail: "/thumbnails/atlas-of-the-bible.png",
    pdf: "https://docs.google.com/viewer?srcid=1U7YeEn8kNc8SKdGcjkZTtRN7uzCcYp6O&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
    author: "Readers Digest",
  },
  {
    title: "NKJV Study Bible",
    thumbnail: "/thumbnails/nkjv-study-bible.png",
    pdf: "https://archive.org/embed/nkjvstudybible",
    author: "Thomas Nelson",
  },
  {
    title: "Scientific Facts of the Bible",
    thumbnail: "/thumbnails/scientific-facts.png",
    pdf: "https://docs.google.com/viewer?srcid=1rGEvNfAdM-QDdb_fJv5e1ae2TX97C1OE&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
    author: "Ray Comfort",
  },
  {
    title: "Zondervan Illustrated Bible Dictionary",
    thumbnail: "/thumbnails/zondervan.png",
    pdf: "https://archive.org/embed/zondervan-illustrated-bible-dictionary_202210",
    author: "J.D. Douglas and Merrill C. Tenney",
  },
  {
    title: "NKJV Study Bible: New King James Version Study Bible",
    thumbnail: "/thumbnails/NKJBibleStudy.png",
    pdf: "https://archive.org/details/nkjvstudybible/mode/2up",
    author:
      "Radmacher, Earl D; Allen, Ronald Barclay; House, H. Wayne; Thomas Nelson Publishers",
  },
];
