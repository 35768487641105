export const JoyVerses = [
  {
    chapter: 16,
    verse: 24,
    reference: "John 16:24",
    text: "Until now you have not asked for anything in my name. Ask and you will receive, and your joy will be complete.",
  },
  {
    chapter: 1,
    verse: 4,
    reference: "1 John 1:4",
    text: "We write this to make our joy complete.",
  },
  {
    chapter: 15,
    verse: 11,
    reference: "John 15:11",
    text: "I have told you this so that my joy may be in you and that your joy may be complete.",
  },
  {
    chapter: 5,
    verse: 22,
    reference: "Galatians 5:22",
    text: "But the fruit of the Spirit is love, joy, peace, forbearance, kindness, goodness, faithfulness,",
  },
  {
    chapter: 4,
    verse: 4,
    reference: "Philippians 4:4",
    text: "Rejoice in the Lord always. I will say it again: Rejoice!",
  },
  {
    chapter: 30,
    verse: 5,
    reference: "Psalm 30:5",
    text: "For his anger lasts only a moment, but his favor lasts a lifetime; weeping may stay for the night, but rejoicing comes in the morning.",
  },
  {
    chapter: 1,
    verse: 8,
    reference: "1 Peter 1:8-9",
    text: "Though you have not seen him, you love him; and even though you do not see him now, you believe in him and are filled with an inexpressible and glorious joy, for you are receiving the end result of your faith, the salvation of your souls.",
  },
  {
    chapter: 2,
    verse: 10,
    reference: "Luke 2:10",
    text: "But the angel said to them, 'Do not be afraid. I bring you good news that will cause great joy for all the people.'",
  },
  {
    chapter: 8,
    verse: 10,
    reference: "Nehemiah 8:10",
    text: "Nehemiah said, 'Go and enjoy choice food and sweet drinks, and send some to those who have nothing prepared. This day is holy to our Lord. Do not grieve, for the joy of the Lord is your strength.'",
  },
  {
    chapter: 12,
    verse: 43,
    reference: "Nehemiah 12:43",
    text: "And on that day they offered great sacrifices, rejoicing because God had given them great joy. The women and children also rejoiced. The sound of rejoicing in Jerusalem could be heard far away.",
  },
  {
    chapter: 16,
    verse: 11,
    reference: "Psalm 16:11",
    text: "You make known to me the path of life; you will fill me with joy in your presence, with eternal pleasures at your right hand.",
  },
  {
    chapter: 32,
    verse: 11,
    reference: "Psalm 32:11",
    text: "Rejoice in the Lord and be glad, you righteous; sing, all you who are upright in heart!",
  },
  {
    chapter: 51,
    verse: 12,
    reference: "Psalm 51:12",
    text: "Restore to me the joy of your salvation and grant me a willing spirit, to sustain me.",
  },
  {
    chapter: 98,
    verse: 4,
    reference: "Psalm 98:4",
    text: "Shout for joy to the Lord, all the earth, burst into jubilant song with music.",
  },
  {
    chapter: 13,
    verse: 17,
    reference: "Proverbs 17:22",
    text: "A cheerful heart is good medicine, but a crushed spirit dries up the bones.",
  },
  {
    chapter: 9,
    verse: 6,
    reference: "Isaiah 9:6",
    text: "For to us a child is born, to us a son is given, and the government will be on his shoulders. And he will be called Wonderful Counselor, Mighty God, Everlasting Father, Prince of Peace.",
  },
  {
    chapter: 61,
    verse: 10,
    reference: "Isaiah 61:10",
    text: "I delight greatly in the Lord; my soul rejoices in my God. For he has clothed me with garments of salvation and arrayed me in a robe of his righteousness, as a bridegroom adorns his head like a priest, and as a bride adorns herself with her jewels.",
  },
  {
    chapter: 12,
    verse: 3,
    reference: "Isaiah 12:3",
    text: "With joy you will draw water from the wells of salvation.",
  },
  {
    chapter: 35,
    verse: 10,
    reference: "Isaiah 35:10",
    text: "And those the Lord has rescued will return. They will enter Zion with singing; everlasting joy will crown their heads. Gladness and joy will overtake them, and sorrow and sighing will flee away.",
  },
  {
    chapter: 14,
    verse: 17,
    reference: "Romans 14:17",
    text: "For the kingdom of God is not a matter of eating and drinking, but of righteousness, peace and joy in the Holy Spirit,",
  },
  {
    chapter: 9,
    verse: 11,
    reference: "Jeremiah 15:16",
    text: "When your words came, I ate them; they were my joy and my heart’s delight, for I bear your name, Lord God Almighty.",
  },
  {
    chapter: 11,
    verse: 9,
    reference: "Zephaniah 3:17",
    text: "The Lord your God is with you, the Mighty Warrior who saves. He will take great delight in you; in his love he will no longer rebuke you, but will rejoice over you with singing.",
  },
  {
    chapter: 3,
    verse: 17,
    reference: "Habakkuk 3:17-18",
    text: "Though the fig tree does not bud and there are no grapes on the vines, though the olive crop fails and the fields produce no food, though there are no sheep in the pen and no cattle in the stalls, yet I will rejoice in the Lord, I will be joyful in God my Savior.",
  },
];
