import React from "react";

const HomeIntro: React.FC = () => {
  return (
    <div className="md:p-10 min-h-auto">
      <h2 className="text-2xl md:text-3xl font-bold text-white uppercase m-5 text-left">
        Living For and Through Jesus Christ Everyday!
      </h2>
      <p className="text-left text-white text-lg md:text-xl m-5">
        Welcome! We are Alan Jones and Maria Luisa Jones, born-again
        Christians wholeheartedly devoted to living for and through our Lord and
        Savior, Jesus Christ. Guided daily by the Holy Spirit, we strive to live
        fully in His grace and truth. <br /><br />
        As a loving husband and wife, and children of our great Lord Jesus
        Christ, we created this platform to share valuable resources for fellow
        Christians and anyone seeking to navigate life’s challenges with faith.<br/><br />
        Our mission is to provide tools and guidance to help others live
        empowered by the Holy Spirit. This site is a non-profit endeavor, built
        from the ground up with the skills and knowledge God has graciously
        bestowed upon us as engineers. We hope these resources and tools will
        inspire and strengthen your walk with Christ, just as they have
        profoundly transformed our own lives. Our prayer is that this platform
        serves as a dependable reference for seeking the wisdom of the Lord
        whenever you need it. May it be a blessing to you as it has been to us.
        God bless you!
      </p>
    </div>
  );
};

export default HomeIntro;
